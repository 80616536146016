import React, { useState, useEffect } from 'react';
import { fetchProfessionals, fetchRooms } from '../api';
import { USER_GROUPS } from '../../config/permissions';
import { useAuth } from '../../hooks/useAuth';
import CreateBooking from './Tabs/CreateBooking';
import ViewBookings from './Tabs/ViewBookings';
import './Booking.css';

function Booking() {
  const { user } = useAuth();
  const userGroups = user?.signInUserSession?.accessToken?.payload['cognito:groups'] || [];
  const isCaixa = userGroups.includes(USER_GROUPS.CAIXA);
  const [activeTab, setActiveTab] = useState('create');
  const [professionals, setProfessionals] = useState([]);
  const [rooms, setRooms] = useState([]);

  useEffect(() => {
    fetchProfessionals()
      .then(data => {
        const professionalsList = Object.entries(data.config.profissionais).map(([name, details]) => ({
          name: name,
          ...details
        }));
        setProfessionals(professionalsList);
      })
      .catch(error => console.error('Error fetching professionals:', error));

    fetchRooms()
      .then(data => {
        const roomsList = Object.entries(data.config.salas).map(([name, details]) => ({
          name: name,
          ...details
        }));
        setRooms(roomsList);
      })
      .catch(error => console.error('Error fetching rooms:', error));

    // If user is CAIXA, force view tab
    if (isCaixa) {
      setActiveTab('view');
    }
  }, [isCaixa]);

  return (
    <div className="booking-container">
      <div className="booking-tabs">
        {!isCaixa && (
          <button 
            className={`tab-button ${activeTab === 'create' ? 'active' : ''}`}
            onClick={() => setActiveTab('create')}
          >
            Criar Agendamento
          </button>
        )}
        <button 
          className={`tab-button ${activeTab === 'view' ? 'active' : ''}`}
          onClick={() => setActiveTab('view')}
        >
          Ver Agendamentos
        </button>
      </div>

      {activeTab === 'create' ? (
        <CreateBooking 
          professionals={professionals}
          rooms={rooms}
        />
      ) : (
        <ViewBookings 
          professionals={professionals}
          rooms={rooms}
        />
      )}
    </div>
  );
}

export default Booking; 