import React from 'react';
import { FaPlus, FaExclamationTriangle, FaEdit, FaFileDownload } from 'react-icons/fa';
import jsPDF from 'jspdf';

function StockTab({ 
    stockItems, 
    searchTerm, 
    setSearchTerm, 
    loading, 
    error, 
    handleShowProductModal, 
    handleShowEditProductModal 
}) {
    const filteredStockItems = stockItems.filter(item => {
        // Always filter out deleted products
        if (item.status === 'deleted') {
            return false;
        }
        
        return !searchTerm || item.searchableText.includes(
            searchTerm.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        );
    }).sort((a, b) => {
        // Sort by criticality: red (most critical) first, then yellow, then normal
        const getCriticality = (item) => {
            if (item.qty < item.min) return 2;
            if (item.qty <= item.ideal) return 1;
            return 0;
        };
        return getCriticality(b) - getCriticality(a);
    });

    const getRowStyle = (item) => {
        if (item.qty < item.min) {
            return { backgroundColor: 'rgba(255, 0, 0, 0.1)' };
        }
        if (item.qty <= item.ideal) {
            return { backgroundColor: 'rgba(255, 255, 0, 0.1)' };
        }
        return {};
    };

    const getStockWarning = (item) => {
        if (item.qty < item.min) {
            return (
                <div className="warning-container">
                    <FaExclamationTriangle style={{ color: 'red' }} />
                    <span className="custom-tooltip">Produto em baixa quantidade</span>
                </div>
            );
        }
        if (item.qty <= item.ideal) {
            return (
                <div className="warning-container">
                    <FaExclamationTriangle style={{ color: '#ffd700' }} />
                    <span className="custom-tooltip">Produto próximo da quantidade mínima</span>
                </div>
            );
        }
        return null;
    };

    const generateStockPDF = async () => {
        try {
            const doc = new jsPDF();
            const pageWidth = doc.internal.pageSize.getWidth();
            
            // Title
            doc.setFontSize(16);
            doc.text('Relatório de Estoque', pageWidth / 2, 20, { align: 'center' });
            
            // Date
            doc.setFontSize(10);
            const currentDate = new Date().toLocaleDateString();
            doc.text(`Gerado em: ${currentDate}`, pageWidth / 2, 30, { align: 'center' });
            
            let yPos = 40;
            const lineHeight = 5;
            const minRowHeight = lineHeight + 4;
            const padding = 8;
            const colWidths = {
                description: 60,
                qty: 20,
                id: 25,
                ideal: 20,
                min: 20,
                price: 30
            };
            
            // Set line width for borders
            doc.setLineWidth(0.1);
            
            // Headers with borders
            doc.setFontSize(8);
            doc.setFont('helvetica', 'bold');
            
            // Helper function to draw cell with wrapped text
            const drawCell = (text, x, y, width, rowHeight, fontSize = 8) => {
                doc.setFontSize(fontSize);
                const lines = doc.splitTextToSize(text || '', width - 4);
                doc.rect(x, y, width, rowHeight);
                
                // Calculate starting Y position to vertically center the text
                const textBlockHeight = lines.length * lineHeight;
                const startY = y + (rowHeight - textBlockHeight) / 2 + lineHeight/2;
                
                lines.forEach((line, i) => {
                    doc.text(line, x + 2, startY + (i * lineHeight));
                });
            };
            
            // Draw headers
            let xPos = 10;
            const headers = {
                'Item': colWidths.description,
                'Quantidade': colWidths.qty,
                'Código': colWidths.id,
                'Ideal': colWidths.ideal,
                'Mínimo': colWidths.min,
                'Preço': colWidths.price
            };
            
            Object.entries(headers).forEach(([header, width]) => {
                drawCell(header, xPos, yPos, width, minRowHeight);
                xPos += width;
            });
            
            yPos += minRowHeight;
            
            // Data rows
            doc.setFont('helvetica', 'normal');
            
            filteredStockItems.forEach((item) => {
                if (yPos > 270) { // Check if we need a new page
                    doc.addPage();
                    yPos = 20;
                    
                    // Draw headers on new page
                    xPos = 10;
                    Object.entries(headers).forEach(([header, width]) => {
                        drawCell(header, xPos, yPos, width, minRowHeight);
                        xPos += width;
                    });
                    yPos += minRowHeight;
                }
                
                const rowHeight = minRowHeight;
                
                // Draw cells
                xPos = 10;
                drawCell(item.description, xPos, yPos, colWidths.description, rowHeight);
                xPos += colWidths.description;
                
                drawCell(item.qty.toString(), xPos, yPos, colWidths.qty, rowHeight);
                xPos += colWidths.qty;
                
                drawCell(item.product_id, xPos, yPos, colWidths.id, rowHeight);
                xPos += colWidths.id;
                
                drawCell(item.ideal.toString(), xPos, yPos, colWidths.ideal, rowHeight);
                xPos += colWidths.ideal;
                
                drawCell(item.min.toString(), xPos, yPos, colWidths.min, rowHeight);
                xPos += colWidths.min;
                
                drawCell(`R$ ${item.price}`, xPos, yPos, colWidths.price, rowHeight);
                
                yPos += rowHeight;
            });
            
            // Save PDF
            doc.save('relatorio-estoque.pdf');
        } catch (error) {
            console.error('Error generating PDF:', error);
            alert('Erro ao gerar PDF. Por favor, tente novamente.');
        }
    };

    const styles = `
        .warning-container {
            position: relative;
            display: inline-block;
            margin-right: 5px;
        }

        .custom-tooltip {
            visibility: hidden;
            background-color: #333;
            color: white;
            text-align: center;
            padding: 5px 10px;
            border-radius: 4px;
            position: absolute;
            z-index: 1;
            bottom: 125%;
            left: 0;
            transform: translateX(10px);
            white-space: nowrap;
            font-size: 12px;
            opacity: 0;
            transition: opacity 0.1s;
        }

        .warning-container:hover .custom-tooltip {
            visibility: visible;
            opacity: 1;
        }
    `;

    return (
        <>
            <style>{styles}</style>
            <div className="table-actions">
                <button className="success" onClick={handleShowProductModal}>
                    <FaPlus /> Adicionar Produto
                </button>
                <button className="report-button" onClick={generateStockPDF}>
                    <FaFileDownload /> Gerar Relatório
                </button>
                <div className="search-container">
                    <input
                        type="text"
                        placeholder="Buscar produtos..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="search-input"
                    />
                </div>
            </div>
            {error && <div className="error-message">{error}</div>}
            <table>
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Quantidade</th>
                        <th>Código do item</th>
                        <th>Ideal</th>
                        <th>Minimo</th>
                        <th>Preço</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredStockItems.map((item, index) => (
                        <tr key={index} style={getRowStyle(item)}>
                            <td>
                                {getStockWarning(item)}
                                {item.description}
                            </td>
                            <td>{item.qty}</td>
                            <td>{item.product_id}</td>
                            <td>{item.ideal}</td>
                            <td>{item.min}</td>
                            <td>R$ {item.price}</td>
                            <td>
                                <button 
                                    className="edit-button"
                                    onClick={() => handleShowEditProductModal(item)}
                                    title="Editar produto"
                                >
                                    <FaEdit />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {loading && <div className="loading">Loading...</div>}
        </>
    );
}

export default StockTab; 