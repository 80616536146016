import React, { useState } from 'react';
import { resetConfigCache } from '../../api';

function CacheTab() {
  const [isCacheReset, setIsCacheReset] = useState(false);
  const [isStockCacheReset, setIsStockCacheReset] = useState(false);
  
  const handleResetCache = () => {
    resetConfigCache();
    setIsCacheReset(true);
    
    // Reset the notification after 3 seconds
    setTimeout(() => {
      setIsCacheReset(false);
    }, 3000);
  };

  const handleResetStockCache = () => {
    localStorage.removeItem('stockRequisitionsCache');
    localStorage.removeItem('stockItemsCache');
    setIsStockCacheReset(true);
    
    setTimeout(() => {
      setIsStockCacheReset(false);
    }, 3000);
  };

  return (
    <>
      <div className="config-section">
        <h3>Cache de Configurações</h3>
        <div className="cache-reset-container">
          <button 
            onClick={handleResetCache} 
            className="cache-reset-button"
          >
            Limpar Cache de Configurações
          </button>
          
          {isCacheReset && (
            <div className="cache-reset-notification">
              Cache resetado com sucesso!
            </div>
          )}
        </div>
        <p className="config-description">
          Limpe o cache de configurações se você suspeitar que os dados 
          de procedimentos ou profissionais estão desatualizados.
        </p>
      </div>

      <div className="config-section">
        <h3>Cache de Estoque</h3>
        <div className="cache-reset-container">
          <button 
            onClick={handleResetStockCache} 
            className="cache-reset-button"
          >
            Limpar Cache de Estoque
          </button>
          
          {isStockCacheReset && (
            <div className="cache-reset-notification">
              Cache de estoque resetado com sucesso!
            </div>
          )}
        </div>
        <p className="config-description">
          Limpe o cache de estoque se você suspeitar que os dados 
          de produtos ou requisições estão desatualizados.
        </p>
      </div>
    </>
  );
}

export default CacheTab; 