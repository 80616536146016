import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import { Auth, Amplify } from 'aws-amplify';
import './App.css';

import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import Procedimentos from './components/Procedimentos';
// import Historico from './components/History/Historico';
import Anamnese from './components/Anamnese/Anamnese';
import Pacientes from './components/Pacientes/Pacientes';
import Booking from './components/Booking/Booking';
import Config from './components/Config/Config';
import Financeiro from './components/Financeiro/Financeiro';
import Estoque from './components/Estoque/Estoque';
import Reports from './components/Reports/Reports';
import ProtectedRoute from './components/ProtectedRoute';
import { ROUTE_PERMISSIONS } from './config/permissions';
import { USER_GROUPS } from './config/permissions';

Amplify.configure({
  Auth: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_vbA0WoIfI',
    userPoolWebClientId: '1mf0d541j8u21c47761cjcsv47',
    identityPoolId: null,
    oauth: {
      domain: 'login.doctor.systems',
      // domain: 'login.doctor.systems',
      lang: 'pt-BR',
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: `${window.location.origin}`,
      redirectSignOut: `${window.location.origin}`,
      responseType: 'code'
    },
  },
});

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    checkUser();
  }, []);


  async function checkUser() {
    try {
      const userData = await Auth.currentAuthenticatedUser();
      setUser(userData);
    } catch (err) {
      setUser(null);
      
      // Get the current Amplify Auth configuration
      const config = Auth.configure();
      const { domain } = config.oauth;
      const clientId = config.userPoolWebClientId;
      
      // Build the auth URL with language parameter
      const redirectUri = encodeURIComponent(window.location.origin);
      const scope = 'email+profile+openid';
      const responseType = 'code';
      
      // Create URL with language parameter
      const authUrl = `https://${domain}/oauth2/authorize?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&lang=pt-BR`;
      
      // Redirect to the login page with language set
      window.location.href = authUrl;
    }
  }

  
  async function signOut() {
    try {
      await Auth.signOut();
      setUser(null);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  }

  function signIn() {
    Auth.federatedSignIn();
  }

  if (!user) {
    return <div className="loading">Redirecionando para login...</div>;
  }

  return (
    <Router>
      <div className="App">
        <Header />
        <div className="card">
          <Sidebar />
          <div className="content">
            <Routes>
              <Route path="/" element={
                <ProtectedRoute allowedGroups={ROUTE_PERMISSIONS.anamnese}>
                  {/* <Anamnese /> */}
                  <Financeiro />
                </ProtectedRoute>
              } />
              {/* <Route path="/protocolo" element={
                <ProtectedRoute allowedGroups={ROUTE_PERMISSIONS.protocolo}>
                  <Procedimentos />
                </ProtectedRoute>
              } /> */}
              <Route path="/agenda" element={
                <ProtectedRoute allowedGroups={ROUTE_PERMISSIONS.agenda}>
                  <div className="agenda"><Booking /></div>
                </ProtectedRoute>
              } />
              {/* <Route path="/historico" element={
                <ProtectedRoute allowedGroups={ROUTE_PERMISSIONS.historico}>
                  <div className="historico"><Historico/></div>
                </ProtectedRoute>
              } /> */}
              <Route path="/anamnese" element={
                <ProtectedRoute allowedGroups={ROUTE_PERMISSIONS.anamnese}>
                  <Anamnese />
                </ProtectedRoute>
              } />
              <Route path="/pacientes" element={
                <ProtectedRoute allowedGroups={ROUTE_PERMISSIONS.pacientes}>
                  <Pacientes />
                </ProtectedRoute>
              } />
              <Route path="/config" element={
                <ProtectedRoute allowedGroups={ROUTE_PERMISSIONS.config}>
                  <Config />
                </ProtectedRoute>
              } />
              <Route path="/financeiro" element={
                <ProtectedRoute allowedGroups={ROUTE_PERMISSIONS.financeiro}>
                  <Financeiro />
                </ProtectedRoute>
              } />
              <Route path="/estoque" element={
                <ProtectedRoute allowedGroups={ROUTE_PERMISSIONS.estoque}>
                  <Estoque />
                </ProtectedRoute>
              } />
              <Route path="/reports" element={
                <ProtectedRoute allowedGroups={ROUTE_PERMISSIONS.reports}>
                  <Reports />
                </ProtectedRoute>
              } />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;