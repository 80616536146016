import axios from 'axios';
import { Auth } from 'aws-amplify'; 

const BASE_URL = 'https://p2ff3kbpwh.execute-api.us-east-1.amazonaws.com/prod/';

// Helper to get the token
async function getAuthToken() {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error) {
    console.error("Error getting auth session:", error);
    throw new Error("Not authenticated");
  }
}

export const fetchProcedimentos = async () => {
  const cachedProcedures = localStorage.getItem('cached_procedimentos');
  const cachedTimestamp = localStorage.getItem('procedimentos_cache_timestamp');

  // Check if cached data exists and is less than 24 hours old
  if (cachedProcedures && cachedTimestamp) {
    const timeSinceCache = Date.now() - parseInt(cachedTimestamp);
    if (timeSinceCache < 24 * 60 * 60 * 1000) {
      return JSON.parse(cachedProcedures);
    }
  }

  try {
    const token = await getAuthToken(); 
    const response = await fetch(`${BASE_URL}get-config`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    const data = await response.json();
    
    // Cache the data and timestamp
    localStorage.setItem('cached_procedimentos', JSON.stringify(data));
    localStorage.setItem('procedimentos_cache_timestamp', Date.now().toString());
    
    // console.log(data);
    return data;
  } catch (error) {
    console.error('Error fetching Procedimentos:', error);
    // Return cached data if available in case of network error
    const cachedProcedures = localStorage.getItem('cached_procedimentos');
    return cachedProcedures ? JSON.parse(cachedProcedures) : [];
  }
};

export const fetchProfessionals = async () => {
  const cachedProfessionals = localStorage.getItem('cached_professionals');
  const cachedTimestamp = localStorage.getItem('professionals_cache_timestamp');

  // Check if cached data exists and is less than 24 hours old
  if (cachedProfessionals && cachedTimestamp) {
    const timeSinceCache = Date.now() - parseInt(cachedTimestamp);
    if (timeSinceCache < 24 * 60 * 60 * 1000) {
      return JSON.parse(cachedProfessionals);
    }
  }

  try {
    const token = await getAuthToken(); 
    const response = await fetch(`${BASE_URL}get-config`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    const data = await response.json();
    
    // Cache the data and timestamp
    localStorage.setItem('cached_professionals', JSON.stringify(data));
    localStorage.setItem('professionals_cache_timestamp', Date.now().toString());
    
    // console.log(data);
    return data;
  } catch (error) {
    console.error('Error fetching Professionals:', error);
    // Return cached data if available in case of network error
    const cachedProfessionals = localStorage.getItem('cached_professionals');
    return cachedProfessionals ? JSON.parse(cachedProfessionals) : [];
  }
};

export const fetchRooms = async () => {
  const cachedRooms = localStorage.getItem('cached_rooms');
  const cachedTimestamp = localStorage.getItem('rooms_cache_timestamp');

  // Check if cached data exists and is less than 24 hours old
  if (cachedRooms && cachedTimestamp) {
    const timeSinceCache = Date.now() - parseInt(cachedTimestamp);
    if (timeSinceCache < 24 * 60 * 60 * 1000) {
      return JSON.parse(cachedRooms);
    }
  }

  try {
    const token = await getAuthToken(); 
    const response = await fetch(`${BASE_URL}get-config`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    const data = await response.json();
    
    // Cache the data and timestamp
    localStorage.setItem('cached_rooms', JSON.stringify(data));
    localStorage.setItem('rooms_cache_timestamp', Date.now().toString());
    
    return data;
  } catch (error) {
    console.error('Error fetching Rooms:', error);
    // Return cached data if available in case of network error
    const cachedRooms = localStorage.getItem('cached_rooms');
    return cachedRooms ? JSON.parse(cachedRooms) : [];
  }
};

export const fetchPatients = async (searchTerm = '') => {
  try {
    const token = await getAuthToken(); // <-- Get the token

    const response = await fetch(`${BASE_URL}get-pacientes`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(searchTerm ? { search: searchTerm } : {}),
    });
    
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching Patients:', error);
    return [];
  }
};

export const createPatient = async (patient) => {
  try {
    const token = await getAuthToken(); 
    const response = await fetch(`${BASE_URL}create-paciente`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(patient),
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({ message: `HTTP error ${response.status}` }));
      throw new Error(errorData.message || `HTTP error ${response.status}`);
    }

    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error('Error creating patient:', error);
    throw error;
  }
};


//       #################### LIMPAR ISSO AQUI ####################
export const fetchAnamnesis = async (patientId = null) => {
  try {
    const token = await getAuthToken(); 
    const response = await fetch(`${BASE_URL}get-anamnese`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(patientId ? { patient_id: patientId } : {}),
    });

    const data = await response.json();
    
    return (data.anamneses || []).map(item => ({
      id: item.anamnese_id,
      date: item.date || 'N/A',
      patient: item.patient_name || 'N/A',
      patientId: item.patient_id || 'N/A',
      content: item.anamnesis_text
    }));
  } catch (error) {
    console.error('Error fetching Anamnesis:', error);
    return [];
  }
};

export const sendToReception = async (data) => {
  try {
    const token = await getAuthToken(); 
    const response = await fetch(`${BASE_URL}send-to-reception`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const result = await response.json();
    console.log(result);
    return result;
  } catch (error) {
    console.error('Error sending to reception:', error);
    throw error;
  }
};

export const createBudget = async (budgetData) => {
  try {
    const token = await getAuthToken(); 
    const response = await fetch(`${BASE_URL}create-budget`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(budgetData),
    });

    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const result = await response.json();
    console.log(result);
    return result;
  } catch (error) {
    console.error('Error creating budget:', error);
    throw error;
  }
};

export const transcribeAudio = async (audioFile, apiKey) => {
  try {
    const token = await getAuthToken(); 
    const formData = new FormData();
    formData.append('file', audioFile);
    formData.append('model', 'whisper-large-v3');
    formData.append('language', 'pt');
    formData.append('prompt', 'Transcrição de áudio em português brasileiro para uma clínica de dermatologia');

    const response = await axios.post(
      'https://api.groq.com/openai/v1/audio/transcriptions',
      formData,
      {
        headers: {
          'Authorization': `Bearer ${apiKey}`,
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    return response.data.text;
  } catch (error) {
    console.error('Transcription error:', error.response || error);
    throw error;
  }
};

export const generateAnamnesis = async (transcriptionText, selectedPatient) => {
  try {
    const token = await getAuthToken(); // <-- Get the token

    const response = await fetch(`${BASE_URL}create-anamnese`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify({
        text: transcriptionText,
        patient: selectedPatient || null
      })
    });

    const data = await response.json();
    
    return data.anamnesis || '';

  } catch (error) {
    console.error('Anamnesis generation error:', error.response?.data || error);
    throw error;
  }
};

//       #########################################################

export const resetConfigCache = () => {
  // Remove cached procedures
  localStorage.removeItem('cached_procedimentos');
  localStorage.removeItem('procedimentos_cache_timestamp');
  
  // Remove cached professionals
  localStorage.removeItem('cached_professionals');
  localStorage.removeItem('professionals_cache_timestamp');

  // Remove cached rooms
  localStorage.removeItem('cached_rooms');
  localStorage.removeItem('rooms_cache_timestamp');
  
  console.log('Configuration cache has been reset');
};

export const updateProceduresConfig = async (proceduresConfig) => {
  try {
    const token = await getAuthToken(); 
    const response = await fetch(`${BASE_URL}update-config`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify({
        // config_id: '1',
        type : 'procedimentos',
        config: proceduresConfig
      }),
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Falha ao atualizar a configuração de procedimentos.');
    }
    
    // Update local cache
    localStorage.removeItem('cached_procedimentos');
    localStorage.removeItem('procedimentos_cache_timestamp');
    
    return await response.json();
  } catch (error) {
    console.error('Error updating procedures config:', error);
    throw error;
  }
};

export const createBooking = async (bookingData) => {
  try {
    const token = await getAuthToken(); 
    const response = await fetch(`${BASE_URL}create-booking`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(bookingData),
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({ message: `HTTP error ${response.status}` }));
      throw new Error(errorData.message || `Erro ao criar agendamento: ${response.status}`);
    }

    const result = await response.json();
    console.log(result);
    return result;
  } catch (error) {
    console.error('Error creating booking:', error);
    throw error;
  }
};

export const fetchBookings = async (queryParams = {}) => {
  try {
    console.log("queryParams", queryParams);
    const token = await getAuthToken(); 
    const response = await fetch(`${BASE_URL}get-booking`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify({
        ...queryParams,
        startDate: queryParams.startDate,
        endDate: queryParams.endDate
      })
    });
    

    const data = await response.json();
    return data.bookings || [];
  } catch (error) {
    console.error('Error fetching Bookings:', error);
    return [];
  }
};

export const updateBooking = async (updates) => {
  try {
    const token = await getAuthToken(); 
    const response = await fetch(`${BASE_URL}update-booking`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(updates),
    });

    if (!response.ok) {
      throw new Error('Problema ao atualizar o agendamento.');
    }


    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error updating booking:', error);
    throw error;
  }
};

export const getStock = async (product_id) => {
    try {
        const token = await getAuthToken(); 
        const url = new URL(`${BASE_URL}get-stock`);
        if (product_id) {
            url.searchParams.append('product_id', product_id);
        }

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        });
        if (!response.ok) {
            throw new Error('Problema ao buscar o item de estoque.');
        }
        return await response.json();

    } catch (error) {
        console.error('Error fetching stock item:', error);
        throw error;
    }
};

export const updateStock = async (stockData) => {
    try {
        const token = await getAuthToken(); 
        const response = await fetch(`${BASE_URL}create-stock-change`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(stockData)
        });
        
        if (!response.ok) {
            throw new Error('Problema ao atualizar o estoque.');
        }
        

        return await response.json();
    } catch (error) {
        console.error('Error updating stock:', error);
        throw error;
    }
};

export const fetchStock = async (paginationToken = null) => {
  try {
    const token = await getAuthToken(); 
    const url = new URL(`${BASE_URL}get-stock`);
    if (paginationToken) {
      url.searchParams.append('pagination_token', paginationToken);
    }

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const data = await response.json();
    return {
      items: data.items || [],
      count: data.count || 0,
      paginationToken: data.pagination_token
    };
  } catch (error) {
    console.error('Error fetching stock:', error);
    throw error;
  }
};

export const getStockMovements = async (paginationToken = null) => {
    try {
        const token = await getAuthToken(); 
        const url = new URL(`${BASE_URL}get-stock-change`);
        if (paginationToken) {
            url.searchParams.append('pagination_token', paginationToken);
        }

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        });
        if (!response.ok) {
            throw new Error('Problema ao buscar as alteracoes de estoque.');
        }
        const data = await response.json();

        return {
          items: data.items || [],
          count: data.count || 0,
          paginationToken: data.pagination_token
        };
      } catch (error) {
        console.error('Error fetching stock movements:', error);
        throw error;
    }
}

export const fetchStockRequisitions = async (paginationToken = null, status = null) => {
  try {
    const token = await getAuthToken(); 
    const url = new URL(`${BASE_URL}get-stock-requisition`);
    if (paginationToken) {
      url.searchParams.append('pagination_token', paginationToken);
    }
    if (status) {
      url.searchParams.append('status', status);
    }

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const data = await response.json();
    return {
      items: data.items || [],
      count: data.count || 0,
      paginationToken: data.pagination_token
    };
  } catch (error) {
    console.error('Error fetching stock requisitions:', error);
    throw error;
  }
};

export const createStockRequisition = async (requisitionData) => {
  try {
    const token = await getAuthToken(); 
    const response = await fetch(`${BASE_URL}create-stock-requisition`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(requisitionData)
    });
    
    if (!response.ok) {
      throw new Error('Falha ao criar a requisicao de estoque.');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error creating stock requisition:', error);
    throw error;
  }
};

export const createStockChanges = async (changes) => {
  try {
    const token = await getAuthToken(); 
    const response = await fetch(`${BASE_URL}create-stock-change`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(changes)
    });
    
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Falha ao processar as alteracoes de estoque.');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error creating stock changes:', error);
    throw error;
  }
};

export const createProduct = async (productData) => {
  try {
    const token = await getAuthToken(); 
    const response = await fetch(`${BASE_URL}create-product`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(productData)
    });
    
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Falha ao criar o produto.');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error creating product:', error);
    throw error;
  }
};

export const createInvoice = async (invoiceData) => {
  try {
    const token = await getAuthToken(); 
    // Handle file uploads first
    const processedData = { ...invoiceData };
    
    // Function to handle single file upload
    const handleFileUpload = async (file, fileName) => {
      if (!file || !fileName) return null;
      
      const date = new Date();
      const datePath = `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}`;
      const uniqueId = Math.random().toString(36).substring(2, 15);
      const fileKey = `${datePath}/${uniqueId}_${fileName}`;
      
      // Get file extension and set content type
      const fileExtension = fileName.split('.').pop().toLowerCase();
      const contentType = {
        'pdf': 'application/pdf',
        'png': 'image/png',
        'jpg': 'image/jpeg',
        'jpeg': 'image/jpeg',
        'gif': 'image/gif'
      }[fileExtension] || 'application/octet-stream';
      
      const { url } = await getPresignedUrl(fileKey, 'clinica-invoices-434812384', contentType);

      console.log("Got a presigned url to upload the file", url);
      
      // Upload file directly without base64 conversion
      await fetch(url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': contentType
        }
      });
      
      const final_file_key = `clinica-invoices-434812384/${fileKey}`;
      console.log("Final file key", final_file_key);
      return final_file_key;
    };
    
    // Handle main invoice file
    if (processedData.file && processedData.file_name) {
      const fileKey = await handleFileUpload(processedData.file, processedData.file_name);
      if (fileKey) {
        delete processedData.file;
        delete processedData.file_name;
        processedData.file_key = fileKey;
      }
    }
    
    // Handle payment files
    if (processedData.payments) {
      const updatedPayments = await Promise.all(processedData.payments.map(async (payment) => {
        if (payment.file && payment.file_name) {
          const fileKey = await handleFileUpload(payment.file, payment.file_name);
          if (fileKey) {
            const { file, file_name, ...rest } = payment;
            return { ...rest, file_key: fileKey };
          }
        }
        return payment;
      }));
      processedData.payments = updatedPayments;
    }

    const response = await fetch(`${BASE_URL}create-invoice`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(processedData)
    });
    
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Falha ao criar a nota fiscal.');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error creating invoice:', error);
    throw error;
  }
};

export const fetchInvoices = async (paginationToken = null, filters = {}) => {
    try {
        const token = await getAuthToken(); 
        const response = await fetch(`${BASE_URL}get-invoices`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                paginationToken,
                ...filters,
                start_date: filters.startDate,
                end_date: filters.endDate,
                exclude_deleted: filters.exclude_deleted || true
            }),
        });

        if (!response.ok) {
            throw new Error('HTTP error ' + response.status);
        }

        const data = await response.json();
        return {
            items: data.invoices || [],
            paginationToken: data.paginationToken
        };
    } catch (error) {
        console.error('Error fetching invoices:', error);
        throw error;
    }
};

export const createPayment = async (paymentData) => {
  try {
    const token = await getAuthToken(); 
    const response = await fetch(`${BASE_URL}create-payment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify({
        ...paymentData,
        status: 'sent'
      })
    });
    
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Falha ao criar o pagamento.');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error creating payment:', error);
    throw error;
  }
};

export const fetchPayments = async (filters = {}) => {
  try {
    const token = await getAuthToken(); 
    const url = new URL(`${BASE_URL}get-payments`);
    if (filters.status) url.searchParams.append('status', filters.status);
    if (filters.start_date) url.searchParams.append('start_date', filters.start_date);
    if (filters.end_date) url.searchParams.append('end_date', filters.end_date);

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });
    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const data = await response.json();
    return data || [];
  } catch (error) {
    console.error('Error fetching payments:', error);
    throw error;
  } 
};

export const updateStockRequisition = async (requisitionId, timestamp, updates = {}, isDelete = false) => {
  try {
    const token = await getAuthToken(); 
    console.log("updating requisition", requisitionId, timestamp, updates, isDelete);
    const response = await fetch(`${BASE_URL}update-requisition`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify({
        requisition_id: requisitionId,
        timestamp: timestamp,
        ...(isDelete ? { action: 'delete' } : updates)
      })
    });
    
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Problema ao atualizar a requisicao de estoque.');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error updating stock requisition:', error);
    throw error;
  }
};

export const updatePayment = async (paymentId, updates = {}, isDelete = false) => {
  try {
    const token = await getAuthToken(); 
    const response = await fetch(`${BASE_URL}update-payment`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify({
        payment_id: paymentId,
        ...(isDelete ? { action: 'delete' } : updates)
      })
    });
    
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Problema ao atualizar o pagamento.');
    }
    

    return await response.json();
  } catch (error) {
    console.error('Error updating payment:', error);
    throw error;
  }
};

export const getFileFromS3 = async (fileKey, download = false) => {
  try {
    const token = await getAuthToken(); 
    console.log("Getting file from S3", fileKey);
    const response = await fetch(`${BASE_URL}get-file-from-s3`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify({ file_key: fileKey })
    });

    if (!response.ok) {
      throw new Error('Problema ao baixar o arquivo.');
    }

    const data = await response.json();
    console.log("File from S3", data);
    if (download) {
      const a = document.createElement('a');
      a.href = data.url;
      a.target = '_blank';
      a.download = fileKey.split('/').pop();
      a.rel = 'noopener noreferrer';
      a.click();
    }
    return data.url;
  } catch (error) {
    console.error('Error downloading file:', error);
    throw error;
  }
};

export const createSupplier = async (supplierData) => {
  try {
    const token = await getAuthToken(); 
    const response = await fetch(`${BASE_URL}create-supplier`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(supplierData),
    });
    
    if (!response.ok) {
      throw new Error('Failed to create supplier');
    }
    
    return response.json();
  } catch (error) {
    console.error('Error creating supplier:', error);
    throw error;
  }
};

export const fetchSuppliers = async (searchTerm) => {
  try {
    const token = await getAuthToken(); 
    const response = await fetch(`${BASE_URL}get-supplier`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify({ search: searchTerm })
    });
    

    if (!response.ok) {
      throw new Error('Failed to fetch suppliers');
    }
    
    const data = await response.json();
    return data.suppliers;
  } catch (error) {
    console.error('Error fetching suppliers:', error);
    throw error;
  }
};

export const updateSupplier = async (supplierId, updates) => {
  try {
    const token = await getAuthToken(); 
    const response = await fetch(`${BASE_URL}update-supplier`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify({
        supplier_id: supplierId,
        updates: updates
      })
    });
    
    if (!response.ok) {
      throw new Error('Failed to update supplier');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error updating supplier:', error);
    throw error;
  }
};

export const updatePatient = async (patientId, currentName, updates) => {
  try {
    const token = await getAuthToken(); 
    const response = await fetch(`${BASE_URL}update-paciente`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify({
        patient_id: patientId,
        reference_name: currentName,
        updates: updates
      })
    });

    if (!response.ok) {
      throw new Error('Problema ao atualizar o paciente.');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error updating patient:', error);
    throw error;
  }
};

export const updateProduct = async (productData) => {
  try {
    const token = await getAuthToken(); 
    const response = await fetch(`${BASE_URL}update-product`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(productData)
    });
    
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Falha ao atualizar o produto.');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error updating product:', error);
    throw error;
  }
};

export const updateInvoice = async (invoiceId, createdAt, updates = {}) => {
  try {
    const token = await getAuthToken(); 
    const response = await fetch(`${BASE_URL}update-invoice`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify({
        invoice_id: invoiceId,
        created_at: createdAt,
        ...updates
      })
    });
    
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Falha ao atualizar a nota fiscal.');
    }
    
    return await response.json();
  } catch (error) {
    console.error('Error updating invoice:', error);
    throw error;
  }
};

export const getPresignedUrl = async (fileName, bucketName, contentType = 'application/pdf') => {
  try {
    const token = await getAuthToken(); 
    const response = await fetch(`${BASE_URL}create-presigned-url`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify({
        file_name: fileName,
        bucket_name: bucketName,
        content_type: contentType
      })
    });

    if (!response.ok) {
      throw new Error('Failed to get presigned URL');
    }

    const data = await response.json();
    return {
      url: data.url,
      fileKey: data.file_key,
      expiresIn: data.expires_in
    };
  } catch (error) {
    console.error('Error getting presigned URL:', error);
    throw error;
  }
};

export const fetchTenant = async () => {
  const cachedTenant = localStorage.getItem('cached_tenant');
  const cachedTimestamp = localStorage.getItem('tenant_cache_timestamp');

  // Check if cached data exists and is less than 24 hours old
  if (cachedTenant && cachedTimestamp) {
    const timeSinceCache = Date.now() - parseInt(cachedTimestamp);
    if (timeSinceCache < 24 * 60 * 60 * 1000) {
      return JSON.parse(cachedTenant);
    }
  }

  try {
    const token = await getAuthToken();
    const response = await fetch(`${BASE_URL}get-tenant`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch tenant information');
    }

    const data = await response.json();
    
    // Cache the data and timestamp
    localStorage.setItem('cached_tenant', JSON.stringify(data.tenant));
    localStorage.setItem('tenant_cache_timestamp', Date.now().toString());
    
    return data.tenant;
  } catch (error) {
    console.error('Error fetching tenant:', error);
    // Return cached data if available in case of network error
    const cachedTenant = localStorage.getItem('cached_tenant');
    return cachedTenant ? JSON.parse(cachedTenant) : null;
  }
};

