import React from 'react';
import { FaPlus, FaTimes } from 'react-icons/fa';

function RequisitionsTab({ 
    requisitions, 
    requisitionsLoading, 
    requisitionsError, 
    requisitionStatusFilter, 
    setRequisitionStatusFilter, 
    handleShowRequisitionModal, 
    handleDeleteRequisition 
}) {
    const filteredRequisitions = requisitions.filter(req => {
        if (requisitionStatusFilter === 'all') return true;
        return req.status === requisitionStatusFilter;
    });

    return (
        <>
            <div className="table-actions">
                <button className="success" onClick={() => handleShowRequisitionModal()}>
                    <FaPlus /> Criar Requisição de saída
                </button>
                <select 
                    value={requisitionStatusFilter}
                    onChange={(e) => setRequisitionStatusFilter(e.target.value)}
                    className="status-filter"
                >
                    <option value="all">Todos</option>
                    <option value="Aberta">Aberta</option>
                    <option value="Fechado">Fechado</option>
                </select>
            </div>
            {requisitionsError && <div className="error-message">{requisitionsError}</div>}
            <table>
                <thead>
                    <tr>
                        <th>Data</th>
                        <th>Requisitante</th>
                        <th>Status</th>
                        <th>Observações</th>
                        <th>Deletar</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredRequisitions.map((req) => (
                        <tr key={req.requisition_id}>
                            <td onClick={() => handleShowRequisitionModal(req)}>{new Date(req.timestamp).toLocaleDateString()}</td>
                            <td onClick={() => handleShowRequisitionModal(req)}>{req.requester}</td>
                            <td onClick={() => handleShowRequisitionModal(req)}>{req.status}</td>
                            <td onClick={() => handleShowRequisitionModal(req)}>{req.notes}</td>
                            <td>
                                <button 
                                    className="delete-button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteRequisition(req.requisition_id, req.timestamp);
                                    }}
                                >
                                    <FaTimes />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {requisitionsLoading && <div className="loading">Loading...</div>}
        </>
    );
}

export default RequisitionsTab; 