import React from 'react';
import { FaPlus, FaEdit } from 'react-icons/fa';
import SupplierSearchSelect from '../../SupplierSearchSelect/SupplierSearchSelect';

function SuppliersTab({ 
    selectedSupplier, 
    handleSupplierSelect, 
    handleShowSupplierModal, 
    handleShowEditSupplierModal 
}) {
    return (
        <>
            <div className="table-actions">
                <button className="success" onClick={handleShowSupplierModal}>
                    <FaPlus /> Adicionar Fornecedor
                </button>
                <div className="search-container">
                    <SupplierSearchSelect onSupplierSelect={handleSupplierSelect} />
                </div>
            </div>
            {selectedSupplier && (
                <div className="supplier-details">
                    <h3>Detalhes do Fornecedor</h3>
                    <div className="details-header">
                        <button 
                            className="edit-button"
                            onClick={handleShowEditSupplierModal}
                            title="Editar fornecedor"
                        >
                            <FaEdit /> Editar
                        </button>
                    </div>
                    <div className="details-grid">
                        <div className="detail-item">
                            <label>Nome:</label>
                            <span>{selectedSupplier.name}</span>
                        </div>
                        <div className="detail-item">
                            <label>CNPJ:</label>
                            <span>{selectedSupplier.cnpj}</span>
                        </div>
                        <div className="detail-item">
                            <label>Email:</label>
                            <span>{selectedSupplier.email}</span>
                        </div>
                        <div className="detail-item">
                            <label>Telefone:</label>
                            <span>{selectedSupplier.phone}</span>
                        </div>
                        <div className="detail-item">
                            <label>ID:</label>
                            <span>{selectedSupplier.supplier_id}</span>
                        </div>
                        {selectedSupplier.address && (
                            <div className="detail-item">
                                <label>Endereço:</label>
                                <span>{selectedSupplier.address}</span>
                            </div>
                        )}
                        {selectedSupplier.contact_person && (
                            <div className="detail-item">
                                <label>Pessoa de Contato:</label>
                                <span>{selectedSupplier.contact_person}</span>
                            </div>
                        )}
                        {selectedSupplier.tax_id && (
                            <div className="detail-item">
                                <label>Inscrição Estadual:</label>
                                <span>{selectedSupplier.tax_id}</span>
                            </div>
                        )}
                        {selectedSupplier.description && (
                            <div className="detail-item full-width">
                                <label>Descrição:</label>
                                <span>{selectedSupplier.description}</span>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}

export default SuppliersTab; 