import React, { useState, useEffect } from 'react';
import { fetchBookings, fetchProfessionals, createPayment, fetchPayments, updatePayment } from '../api';
import './Financeiro.css';
import PaymentModal from './PaymentModal';
import CommissionModal from './CommissionModal';
import PaymentInfoModal from './PaymentInfoModal';
import ViewBookingModal from './Modals/ViewBookingModal';
import { ComissoesTab, PagamentosTab } from './Tabs';
import jsPDF from 'jspdf';

function Financeiro() {
  const [activeTab, setActiveTab] = useState('comissoes');
  const [bookings, setBookings] = useState([]);
  const [bookingsLoading, setBookingsLoading] = useState(false);
  const [professionals, setProfessionals] = useState([]);
  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [comissionStatus, setComissionStatus] = useState('unpaid');
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [payments, setPayments] = useState([]);
  const [paymentsLoading, setPaymentsLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('sent');
  const [paymentStartDate, setPaymentStartDate] = useState('');
  const [paymentEndDate, setPaymentEndDate] = useState('');
  const [showCommissionModal, setShowCommissionModal] = useState(false);
  const [invoicesError, setError] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [showPaymentInfoModal, setShowPaymentInfoModal] = useState(false);

  useEffect(() => {
    fetchProfessionals()
      .then(data => {
        const professionalsList = Object.entries(data.config.profissionais).map(([name, details]) => ({
          name: name,
          ...details
        }));
        setProfessionals(professionalsList);
      })
      .catch(error => console.error('Error fetching professionals:', error));

    // Load today's bookings with proper date formatting
    const today = new Date().toISOString().split('T')[0];
    const queryParams = {
      startDate: `${today}`,
      endDate: `${today}`,
      comissionStatus: comissionStatus
    };
    loadBookings(queryParams);

    // Set default dates to current week's Monday and Friday
    const now = new Date();
    const monday = new Date(now);
    monday.setDate(now.getDate() - now.getDay() + 1);
    const friday = new Date(now);
    friday.setDate(now.getDate() - now.getDay() + 5);
    
    setPaymentStartDate(monday.toISOString().split('T')[0]);
    setPaymentEndDate(friday.toISOString().split('T')[0]);
  }, []);

  useEffect(() => {
    if (activeTab === 'pagamentos') {
      loadPayments();
    }
  }, [activeTab, paymentStatus, paymentStartDate, paymentEndDate]);

  const loadBookings = async (queryParams = {}) => {
    try {
      setBookingsLoading(true);
      
      // Format dates to match backend expectations
      const formattedParams = {
        ...queryParams
      };

      if (queryParams.startDate) {
        formattedParams.startDate = `${queryParams.startDate}`;
        formattedParams.endDate = `${queryParams.endDate}`;
      }

      if (comissionStatus) {
        formattedParams.comissionStatus = comissionStatus;
      }

      const bookingsData = await fetchBookings(formattedParams);
      setBookings(bookingsData);
    } catch (error) {
      console.error('Error fetching bookings:', error);
    } finally {
      setBookingsLoading(false);
    }
  };

  const loadPayments = async () => {
    try {
      setPaymentsLoading(true);
      const filters = {
        status: paymentStatus,
        start_date: `${paymentStartDate}T12:00:00Z`,
        end_date: `${paymentEndDate}T12:00:00Z`
      };
      const data = await fetchPayments(filters);
      setPayments(data);
    } catch (error) {
      console.error('Error loading payments:', error);
    } finally {
      setPaymentsLoading(false);
    }
  };

  const handleSearch = () => {
    if (!startDate || !endDate) {
      alert('Por favor, selecione as datas de início e fim');
      return;
    }

    const queryParams = {
      startDate: `${startDate}`,
      endDate: `${endDate}`
    };

    loadBookings(queryParams);
  };

  const calculateTotalValue = (procedures) => {
    return procedures.reduce((total, proc) => total + proc.valor, 0);
  };

  const groupBookingsByProfessional = () => {
    const groupedBookings = {};
    
    bookings
      .filter(booking => booking.status === 'Agendado' || booking.status === 'Realizado')
      .forEach(booking => {
        const profId = booking.profissional;
        if (!groupedBookings[profId]) {
          groupedBookings[profId] = {
            bookings: [],
            total: 0,
            totalComission: 0
          };
        }
        const bookingTotal = calculateTotalValue(booking.procedimentos);
        groupedBookings[profId].bookings.push(booking);
        groupedBookings[profId].total += bookingTotal;
        groupedBookings[profId].totalComission += parseFloat(booking.comission || 0);
      });
    
    return groupedBookings;
  };

  const getProfessionalName = (professionalId) => {
    const professional = professionals.find(p => p.id === professionalId);
    return professional ? professional.name : professionalId;
  };

  const handleRowClick = (booking) => {
    setSelectedBooking(booking);
    setShowModal(true);
  };

  const handleQuickFilter = (days) => {
    const end = new Date();
    const start = new Date();
    start.setDate(start.getDate() - days);
    
    const startStr = start.toISOString().split('T')[0];
    const endStr = end.toISOString().split('T')[0];
    
    setStartDate(startStr);
    setEndDate(endStr);
    
    loadBookings({
      startDate: `${startStr}`,
      endDate: `${endStr}`
    });
  };

  const handleCreatePayment = async (paymentData) => {
    try {
      await createPayment(paymentData);
      loadPayments(); // Refresh the payments list
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeletePayment = async (paymentId) => {
    if (window.confirm('Quer mesmo deletar o pagamento?')) {
      try {
        await updatePayment(paymentId, {}, true);
        // Refresh payments list
        loadPayments();
      } catch (err) {
        setError('Failed to delete payment');
        console.error(err);
      }
    }
  };

  const generatePDF = async () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();
    
    // Helper function to format text as title and clean whitespace
    const formatText = (text) => {
      if (!text) return '';
      // Trim whitespace and remove empty lines
      const cleaned = text.trim().replace(/^\s*[\r\n]/gm, '');
      // Capitalize first letter, rest lowercase
      return cleaned.charAt(0).toUpperCase() + cleaned.slice(1).toLowerCase();
    };

    // Helper function to wrap text and return wrapped lines and height
    const wrapText = (text, width, fontSize) => {
      if (!text) return { lines: [], height: 0 };
      doc.setFontSize(fontSize);
      const lines = doc.splitTextToSize(text, width - 4);
      // Calculate height with extra padding for multiple lines
      return { 
        lines,
        height: (lines.length * lineHeight) + (lines.length > 1 ? padding : 0)
      };
    };

    // Helper function to draw cell with wrapped text
    const drawCell = (text, x, y, width, rowHeight, fontSize = 8) => {
      const { lines } = wrapText(text, width, fontSize);
      // Draw rectangle for the entire cell height
      doc.rect(x, y, width, rowHeight);
      
      // Calculate starting Y position to vertically center the text
      const textBlockHeight = lines.length * lineHeight;
      const startY = y + (rowHeight - textBlockHeight) / 2 + lineHeight/2;
      
      // Draw each line of text with proper spacing
      lines.forEach((line, i) => {
        doc.text(line, x + 2, startY + (i * lineHeight));
      });
    };
    
    // Title
    doc.setFontSize(16);
    doc.text(`Movimento Caixa ${startDate}`, pageWidth / 2, 20, { align: 'center' });
    
    let yPos = 40;
    const lineHeight = 5; // Increased from 4 to 5 for better spacing
    const minRowHeight = lineHeight + 4;
    const padding = 8; // New explicit padding value
    const colWidths = {
      nome: 40,
      procedimento: 40,
      pagamento: 25,
      valor: 20,
      observacao: 80
    };
    
    // Set line width for borders
    doc.setLineWidth(0.1);
    
    // Headers with borders
    doc.setFontSize(8);
    doc.setFont('helvetica', 'bold');
    
    // Draw headers
    let xPos = 2;
    Object.entries({
      'Nome': colWidths.nome,
      'Procedimento': colWidths.procedimento,
      'Pagamento': colWidths.pagamento,
      'Valor': colWidths.valor,
      'Observação': colWidths.observacao
    }).forEach(([header, width]) => {
      drawCell(header, xPos, yPos, width, minRowHeight);
      xPos += width;
    });
    
    yPos += minRowHeight;
    
    // Data
    doc.setFont('helvetica', 'normal');
    Object.entries(groupBookingsByProfessional()).forEach(([profId, data]) => {
      data.bookings.forEach(booking => {
        if (yPos > 270) { // Check if we need a new page
          doc.addPage();
          yPos = 20;
        }
        
        // Format name to show only first and second names
        const names = booking.paciente.split(' ');
        const formattedName = names.length > 1 ? 
          `${names[0]} ${names[1]}` : 
          names[0];

        // Calculate wrapped text for all cells
        const wrappedTexts = [
          wrapText(formattedName, colWidths.nome),
          wrapText(booking.procedimentos[0]?.nome || '-', colWidths.procedimento),
          wrapText(booking.paymentMethod || '-', colWidths.pagamento),
          wrapText(`R$ ${(parseFloat(booking.valorTotal) || 0)}`, colWidths.valor),
          wrapText(formatText(booking.observacao), colWidths.observacao)
        ];

        // Calculate row height based on the maximum content height
        const contentHeight = Math.max(...wrappedTexts.map(wt => wt.height));
        const rowHeight = Math.max(contentHeight, minRowHeight);

        // Draw all cells with the same row height
        let xPos = 2;
        drawCell(formattedName, xPos, yPos, colWidths.nome, rowHeight);
        xPos += colWidths.nome;
        
        drawCell(booking.procedimentos[0]?.nome || '-', xPos, yPos, colWidths.procedimento, rowHeight);
        xPos += colWidths.procedimento;
        
        drawCell(booking.paymentMethod || '-', xPos, yPos, colWidths.pagamento, rowHeight);
        xPos += colWidths.pagamento;
        
        drawCell(`R$ ${(parseFloat(booking.valorTotal) || 0)}`, xPos, yPos, colWidths.valor, rowHeight);
        xPos += colWidths.valor;
        
        drawCell(formatText(booking.observacao), xPos, yPos, colWidths.observacao, rowHeight);
        
        yPos += rowHeight;
      });
    });
    
    // Save PDF
    doc.save(`movimento-caixa-${startDate}.pdf`);
  };

  const handleGeneratePDF = async () => {
    try {
      await generatePDF();
    } catch (error) {
      console.error('Error generating PDF:', error);
      alert('Erro ao gerar PDF. Por favor, tente novamente.');
    }
  };

  return (
    <div className="financeiro-container">
      <div className="financeiro-tabs">
        <button 
          className={`tab-button ${activeTab === 'comissoes' ? 'active' : ''}`}
          onClick={() => setActiveTab('comissoes')}
        >
          Comissões
        </button>
        <button 
          className={`tab-button ${activeTab === 'pagamentos' ? 'active' : ''}`}
          onClick={() => setActiveTab('pagamentos')}
        >
          Pagamentos
        </button>
      </div>

      {activeTab === 'comissoes' && (
        <ComissoesTab
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          comissionStatus={comissionStatus}
          setComissionStatus={setComissionStatus}
          bookingsLoading={bookingsLoading}
          bookings={bookings}
          handleSearch={handleSearch}
          handleQuickFilter={handleQuickFilter}
          groupBookingsByProfessional={groupBookingsByProfessional}
          getProfessionalName={getProfessionalName}
          handleRowClick={handleRowClick}
          handleGeneratePDF={handleGeneratePDF}
          setBookings={setBookings}
        />
      )}

      {activeTab === 'pagamentos' && (
        <PagamentosTab
          paymentStartDate={paymentStartDate}
          setPaymentStartDate={setPaymentStartDate}
          paymentEndDate={paymentEndDate}
          setPaymentEndDate={setPaymentEndDate}
          paymentStatus={paymentStatus}
          setPaymentStatus={setPaymentStatus}
          showPaymentModal={showPaymentModal}
          setShowPaymentModal={setShowPaymentModal}
          showCommissionModal={showCommissionModal}
          setShowCommissionModal={setShowCommissionModal}
          invoicesError={invoicesError}
          paymentsLoading={paymentsLoading}
          payments={payments}
          handleDeletePayment={handleDeletePayment}
          setSelectedPayment={setSelectedPayment}
          setShowPaymentInfoModal={setShowPaymentInfoModal}
        />
      )}

      <ViewBookingModal
        booking={selectedBooking}
        showModal={showModal}
        startDate={startDate}
        endDate={endDate}
        onUpdate={() => {
          const queryParams = {
            startDate: `${startDate}`,
            endDate: `${endDate}`
          };
          loadBookings(queryParams);
        }}
        onClose={() => {
          setShowModal(false);
          setSelectedBooking(null);
        }}
      />

      <PaymentModal
        show={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
        onSubmit={handleCreatePayment}
      />

      <CommissionModal
        show={showCommissionModal}
        onClose={() => {
          setShowCommissionModal(false);
          loadPayments(); // Refresh payments after modal closes
        }}
      />

      <PaymentInfoModal
        show={showPaymentInfoModal}
        payment={selectedPayment}
        onClose={() => {
          setShowPaymentInfoModal(false);
          setSelectedPayment(null);
        }}
        onUpdate={() => loadPayments()}
      />
    </div>
  );
}

export default Financeiro; 