import React from 'react';

function MovementsTab({ stockMovements, movementsLoading, movementsError }) {
    return (
        <>
            {movementsError && <div className="error-message">{movementsError}</div>}
            <table>
                <thead>
                    <tr>
                        <th>Data</th>
                        <th>Item</th>
                        <th>Tipo</th>
                        <th>Quantidade</th>
                        <th>NF</th>
                        <th>Requisitante</th>
                        <th>Observações</th>
                    </tr>
                </thead>
                <tbody>
                    {stockMovements.map((movement) => (
                        <tr key={movement.change_id}>
                            <td>{new Date(movement.timestamp).toLocaleDateString()}</td>
                            <td>{movement.product_id}</td>
                            <td>{movement.type === 'in' ? 'Entrada' : 'Saída'}</td>
                            <td>{Math.abs(movement.quantity_change)}</td>
                            <td>{movement.nf_number}</td>
                            <td>{movement.requester}</td>
                            <td>{movement.notes}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {movementsLoading && <div className="loading">Loading...</div>}
        </>
    );
}

export default MovementsTab; 