import React, { useState, useEffect } from 'react';
import { FaPlus, FaTrash, FaCheck, FaPrint } from 'react-icons/fa';
import { getStock, createStockChanges } from '../../api';
import ProductSearchSelect from '../../ProductSearchSelect/ProductSearchSelect';
import '../Estoque.css';

function RequisitionModal({ show, onClose, requisition, onSubmit, onAttendRequisition }) {
    const [formData, setFormData] = useState({
        requester: '',
        notes: '',
        items: []
    });
    const [selectedQuantity, setSelectedQuantity] = useState(1);
    const [error, setError] = useState('');
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        if (requisition) {
            setFormData({
                requester: requisition.requester,
                notes: requisition.notes,
                items: requisition.items
            });
        } else {
            setFormData({
                requester: '',
                notes: '',
                items: []
            });
        }
    }, [requisition]);

    // Add keyboard and click event handlers
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (show && e.key === 'Escape') {
                onClose();
            }
        };

        if (show) {
            document.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [show, onClose]);

    if (!show) return null;

    const handleProductSelect = (item) => {
        setFormData(prev => ({
            ...prev,
            items: [
                ...prev.items,
                {
                    product_id: item.product_id,
                    description: item.description,
                    quantity: selectedQuantity
                }
            ]
        }));
        setSelectedQuantity(1);
    };

    const handleRemoveItem = (index) => {
        setFormData(prev => ({
            ...prev,
            items: prev.items.filter((_, i) => i !== index)
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.items.length === 0) {
            alert('Adicione pelo menos um item à requisição');
            return;
        }
        onSubmit(formData);
        // Reset form to initial state
        setFormData({
            requester: '',
            notes: '',
            items: []
        });
        setSelectedQuantity(1);
        setError('');
    };

    const handleAttendRequisition = async () => {
        try {
            setProcessing(true);
            setError('');

            // Create stock changes first
            const changes = formData.items.map(item => ({
                product_id: item.product_id,
                quantity_change: -item.quantity,
                type: 'out',
                nf_number: '-',
                requester: requisition.requester,
                notes: `Atendimento da requisição ${requisition.requisition_id}`,
                requisition_id: requisition.requisition_id,
                requisition_timestamp: requisition.timestamp
            }));

            // First, updates the stock. Actually the backend function for this does both
            await createStockChanges(changes);

            //This just updates the states.
            await onAttendRequisition(requisition.requisition_id, requisition.timestamp);
            onClose();
        } catch (err) {
            setError(err.message || 'Erro ao atender requisição');
            console.error(err);
        } finally {
            setProcessing(false);
        }
    };

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    const handlePrint = () => {
        const printContent = `
            <html>
                <head>
                    <title>Requisição de Material</title>
                    <style>
                        body { font-family: Arial, sans-serif; padding: 20px; }
                        .header { text-align: center; margin-bottom: 30px; }
                        .info { margin-bottom: 20px; }
                        table { width: 100%; border-collapse: collapse; margin-bottom: 30px; }
                        th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                        .signature { margin-top: 50px; padding-top: 20px; border-top: 1px solid #000; width: 200px; text-align: center; }
                    </style>
                </head>
                <body>
                    <div class="header">
                        <h2>Requisição de Material</h2>
                    </div>
                    <div class="info">
                        <p><strong>Requisitante:</strong> ${formData.requester}</p>
                        <p><strong>Data:</strong> ${new Date().toLocaleDateString()}</p>
                        <p><strong>Status:</strong> ${requisition ? requisition.status : 'Nova'}</p>
                        ${formData.notes ? `<p><strong>Observações:</strong> ${formData.notes}</p>` : ''}
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Quantidade</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${formData.items.map(item => `
                                <tr>
                                    <td>${item.description}</td>
                                    <td>${item.quantity}</td>
                                </tr>
                            `).join('')}
                        </tbody>
                    </table>
                    <div class="signature">
                        <p>_____________________________</p>
                        <p>Assinatura do Requisitante</p>
                    </div>
                </body>
            </html>
        `;

        const printWindow = window.open('', '_blank');
        printWindow.document.write(printContent);
        printWindow.document.close();
        printWindow.print();
    };

    return (
        <div 
            className="modal-overlay" 
            onClick={handleOverlayClick}
        >
            <div className="modal-content">
                <h2>{requisition ? 'Detalhes da Requisição' : 'Nova Requisição'}</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Requisitante:</label>
                        <input
                            type="text"
                            value={formData.requester}
                            onChange={(e) => setFormData(prev => ({ ...prev, requester: e.target.value }))}
                            required
                            disabled={!!requisition}
                        />
                    </div>

                    {requisition && requisition.status !== 'Aberta' && (
                        <div className="form-group">
                            <label>Fechado em:</label>
                            <div>{new Date(requisition.last_updated).toLocaleDateString()}</div>
                        </div>
                    )}

                    <div className="form-group">
                        <label>Status:</label>
                        {requisition && (
                            <div className={`status-badge ${requisition.status.toLowerCase()}`}>
                                {requisition.status}
                            </div>
                        )}
                    </div>

                    <div className="form-group">
                        <label>Observações:</label>
                        <textarea
                            value={formData.notes}
                            onChange={(e) => setFormData(prev => ({ ...prev, notes: e.target.value }))}
                            disabled={!!requisition}
                        />
                    </div>

                    {!requisition && (
                        <div className="form-group">
                            <label>Adicionar Item:</label>
                            {error && <div className="error-message">{error}</div>}
                            <div className="add-item-row">
                                <div className="product-search-wrapper">
                                    <ProductSearchSelect
                                        onSelect={handleProductSelect}
                                        placeholder="Buscar produto..."
                                    />
                                </div>
                                <input
                                    type="number"
                                    min="1"
                                    value={selectedQuantity}
                                    onChange={(e) => setSelectedQuantity(parseInt(e.target.value))}
                                    className="quantity-input"
                                />
                            </div>
                        </div>
                    )}

                    <div className="items-list">
                        <h3>Itens da Requisição</h3>
                        <table>
                            <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Quantidade</th>
                                    {!requisition && <th>Ações</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {formData.items.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.description}</td>
                                        <td>{item.quantity}</td>
                                        {!requisition && (
                                            <td>
                                                <button
                                                    type="button"
                                                    className="danger"
                                                    onClick={() => handleRemoveItem(index)}
                                                >
                                                    <FaTrash />
                                                </button>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="modal-actions">
                        {!requisition && (
                            <button type="submit" className="success">
                                Criar Requisição
                            </button>
                        )}
                        {requisition && requisition.status === 'Aberta' && (
                            <button 
                                type="button" 
                                className="success"
                                onClick={handleAttendRequisition}
                                disabled={processing}
                            >
                                <FaCheck /> {processing ? 'Processando...' : 'Atender Requisição'}
                            </button>
                        )}
                        {requisition && (
                            <button 
                                type="button" 
                                className="secondary"
                                onClick={handlePrint}
                            >
                                <FaPrint /> Imprimir
                            </button>
                        )}
                        <button type="button" onClick={onClose}>
                            {requisition ? 'Fechar' : 'Cancelar'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default RequisitionModal; 