import React from 'react';
import { ClipLoader } from 'react-spinners';
import { FaFileDownload } from 'react-icons/fa';

function ComissoesTab({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  comissionStatus,
  setComissionStatus,
  bookingsLoading,
  bookings,
  handleSearch,
  handleQuickFilter,
  groupBookingsByProfessional,
  getProfessionalName,
  handleRowClick,
  handleGeneratePDF,
  setBookings
}) {
  return (
    <div className="caixa-container">
      <div className="caixa-search-filters">
        <div className="date-filters">
          <button 
            className="report-button"
            onClick={handleGeneratePDF}
          >
            <FaFileDownload /> Gerar Relatório
          </button>
          <div className="quick-filters">
            <button 
              className="quick-filter-button"
              onClick={() => handleQuickFilter(7)}
            >
              7d
            </button>
            <button 
              className="quick-filter-button"
              onClick={() => handleQuickFilter(15)}
            >
              15d
            </button>
          </div>
          <div className="date-input-group">
            <label>Início:</label>
            <input 
              type="date" 
              value={startDate} 
              onChange={(e) => {
                setStartDate(e.target.value);
                setBookings([]); // Clear current bookings
              }}
              className="date-input"
              required
              onClick={(e) => e.target.showPicker()}
            />
          </div>
          <div className="date-input-group">
            <label>Fim:</label>
            <input 
              type="date" 
              value={endDate} 
              onChange={(e) => {
                setEndDate(e.target.value);
                setBookings([]); // Clear current bookings
              }}
              className="date-input"
              required
              onClick={(e) => e.target.showPicker()}
            />
          </div>
          <div className="date-input-group">
            <label>Status da Comissão:</label>
            <select
              value={comissionStatus}
              onChange={(e) => {
                setComissionStatus(e.target.value);
                setBookings([]); // Clear current bookings
              }}
              className="status-select"
            >
              <option value="unpaid">Aberta</option>
              <option value="sent">Enviada para pagamento</option>
            </select>
          </div>
          <button 
            className="dark-button"
            onClick={handleSearch}
            disabled={bookingsLoading}
          >
            Filtrar
          </button>
        </div>
      </div>

      {bookingsLoading ? (
        <div className="loading-container">
          <ClipLoader size={50} color={"#4a90e2"} />
        </div>
      ) : (
        <div className="bookings-list">
          {Object.entries(groupBookingsByProfessional()).map(([profId, data]) => (
            <div key={profId} className="professional-group">
              <h3>{getProfessionalName(profId)}</h3>
              <table>
                <thead>
                  <tr>
                    <th>Paciente</th>
                    <th>Procedimentos</th>
                    <th>Valor</th>
                    <th>Comissão</th>
                    <th>Forma de Pagamento</th>
                    <th>Pagamento</th>
                    <th>Procedimento</th>
                  </tr>
                </thead>
                <tbody>
                  {data.bookings.map((booking, index) => {
                    const isCompleted = booking.status === 'Realizado';
                    const bookingTotal = parseInt(booking.valorTotal, 10);

                    return (
                      <tr 
                        key={index}
                        onClick={() => handleRowClick(booking)}
                        style={{ 
                          backgroundColor: isCompleted ? '#2e7d32' : 'inherit',
                          color: isCompleted ? '#ffffff' : 'inherit',
                          cursor: 'pointer'
                        }}
                      >
                        <td>{booking.paciente}</td>
                        <td>
                          {booking.procedimentos?.map((proc, i) => (
                            <div key={i}>{proc.nome}</div>
                          ))}
                        </td>
                        <td>R$ {(parseFloat(bookingTotal) || 0).toFixed(2)}</td>
                        <td>R$ {parseFloat(booking.comission || 0).toFixed(2)}</td>
                        <td>{booking.paymentMethod || '-'}</td>
                        <td>{booking.paymentStatus || 'Pendente'}</td>
                        <td>{booking.status || 'Agendado'}</td>
                      </tr>
                    );
                  })}
                  <tr className="total-row">
                    <td colSpan="2"><strong>Total do Profissional</strong></td>
                    <td><strong>R$ {(data.bookings.reduce((sum, booking) => sum + (parseFloat(booking.valorTotal) || 0), 0)).toFixed(2)}</strong></td>
                    <td><strong>R$ {parseFloat(data.totalComission || 0).toFixed(2)}</strong></td>
                    <td colSpan="3"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
          {Object.keys(groupBookingsByProfessional()).length > 0 && (
            <div className="grand-total">
              <h3>Total Geral</h3>
              <div className="total-value">
                <div>
                  Valor: R$ {(Object.values(groupBookingsByProfessional())
                    .reduce((total, data) => 
                      total + data.bookings.reduce((sum, booking) => 
                        sum + (parseFloat(booking.valorTotal) || 0), 0), 0))
                    .toFixed(2)}
                </div>
                <div>
                  Comissões: R$ {(Object.values(groupBookingsByProfessional())
                    .reduce((total, data) => total + parseFloat(data.totalComission || 0), 0))
                    .toFixed(2)}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ComissoesTab; 