import React, { useState, useEffect } from 'react';
import { getStock, updateStock } from '../../api';

function StockModal({ show, onClose, type, onSubmit }) {
    const [formData, setFormData] = useState({
        product_id: '',
        quantity_change: '',
        nf_number: '',
        requester: type === 'add' ? '-' : '',
        notes: '',
        date: new Date().toISOString().split('T')[0]
    });
    const [productDetails, setProductDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Reset form when type changes
    useEffect(() => {
        setFormData(prev => ({
            ...prev,
            requester: type === 'add' ? '-' : ''
        }));
    }, [type]);

    const handleProductIdChange = async (id) => {
        setFormData(prev => ({ ...prev, product_id: id }));
        if (id) {
            try {
                setLoading(true);
                setError(null);
                const response = await getStock(id);
                if (response && response.items && response.items[0]) {
                    setProductDetails(response.items[0]);
                } else {
                    setError('Produto não encontrado');
                    setProductDetails(null);
                }
            } catch (err) {
                setError('Erro ao buscar produto');
                setProductDetails(null);
            } finally {
                setLoading(false);
            }
        } else {
            setProductDetails(null);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            setError(null);

            const stockChangeData = {
                product_id: formData.product_id,
                quantity_change: type === 'add' ? 
                    parseInt(formData.quantity_change) : 
                    -parseInt(formData.quantity_change),
                type: type === 'add' ? 'in' : 'out',
                nf_number: formData.nf_number,
                requester: type === 'add' ? '-' : formData.requester,
                notes: formData.notes || ''
            };

            await updateStock(stockChangeData);
            onSubmit(stockChangeData);
        } catch (err) {
            setError('Erro ao atualizar estoque: ' + err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        show && (
            <div className="modal-overlay" onClick={onClose}>
                <div className="modal-content" onClick={e => e.stopPropagation()}>
                    <h2>{type === 'add' ? 'Adicionar ao Estoque' : 'Remover do Estoque'}</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label>Código do Produto</label>
                            <input
                                type="text"
                                placeholder="Digite o código do produto"
                                value={formData.product_id}
                                onChange={(e) => handleProductIdChange(e.target.value)}
                                required
                            />
                        </div>

                        {loading && <div className="loading-message">Processando...</div>}
                        {error && <div className="error-message">{error}</div>}
                        {productDetails && (
                            <div className="product-details">
                                <p>Nome do Produto: {productDetails.description}</p>
                                <p>Quantidade Atual: {productDetails.qty}</p>
                            </div>
                        )}

                        <div className="form-group">
                            <label>Quantidade</label>
                            <input
                                type="number"
                                min="1"
                                value={formData.quantity_change}
                                onChange={(e) => setFormData({...formData, quantity_change: e.target.value})}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label>Número da Nota Fiscal</label>
                            <input
                                type="text"
                                value={formData.nf_number}
                                onChange={(e) => setFormData({...formData, nf_number: e.target.value})}
                                required
                            />
                        </div>

                        {type === 'remove' && (
                            <div className="form-group">
                                <label>Requisitante</label>
                                <input
                                    type="text"
                                    value={formData.requester}
                                    onChange={(e) => setFormData({...formData, requester: e.target.value})}
                                    required
                                />
                            </div>
                        )}

                        <div className="form-group">
                            <label>Observações</label>
                            <textarea
                                rows={3}
                                value={formData.notes}
                                onChange={(e) => setFormData({...formData, notes: e.target.value})}
                            />
                        </div>

                        <div className="form-group">
                            <label>Data</label>
                            <input
                                type="date"
                                value={formData.date}
                                onChange={(e) => setFormData({...formData, date: e.target.value})}
                                required
                            />
                        </div>

                        <div className="modal-actions">
                            <button type="button" className="secondary" onClick={onClose} disabled={loading}>
                                Cancelar
                            </button>
                            <button type="submit" className="primary" disabled={loading}>
                                {loading ? 'Processando...' : type === 'add' ? 'Adicionar' : 'Remover'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    );
}

export default StockModal; 