import React, { useEffect } from 'react';
import { getFileFromS3 } from '../../api';
import '../Estoque.css';

function InvoiceInfoModal({ show, onClose, invoice }) {
    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };
        console.log(invoice);
        window.addEventListener('keydown', handleEsc);
        return () => window.removeEventListener('keydown', handleEsc);
    }, [onClose]);

    if (!show) return null;

    const handleBackgroundClick = (e) => {
        if (e.target.className === 'modal-overlay') {
            onClose();
        }
    };

    const handleDownload = async () => {
        console.log("Downloading file", invoice.file_key);
        try {
            await getFileFromS3(invoice.file_key, true);
        } catch (error) {
            console.error('Error downloading file:', error);
            alert('Erro ao baixar o arquivo');
        }
    };

    return (
        <div className="modal-overlay" onClick={handleBackgroundClick}>
            <div className="modal-container">
                <div className="modal-header">
                    <h2>Detalhes da Nota Fiscal</h2>
                    <button className="close-button" onClick={onClose}>&times;</button>
                </div>
                <div className="modal-body">
                    <div className="invoice-details">
                        <div className="details-section">
                            <div className="details-row">
                                <div className="details-group">
                                    <label>Código de referência</label>
                                    <span>{invoice.invoice_id || '-'}</span>
                                </div>
                                {invoice.file_key && (
                                    <div className="details-group">
                                        <button 
                                            className="primary-button" 
                                            onClick={handleDownload}
                                        >
                                            Baixar arquivo
                                        </button>
                                    </div>
                                )}
                            </div>



                            <div className="details-row">
                                <div className="details-group">
                                    <label>Data</label>
                                    <span>{new Date(invoice.created_at).toLocaleDateString()}</span>
                                </div>
                                <div className="details-group">
                                    <label>Data Vencimento</label>
                                    <span>{invoice.expiration_date ? new Date(invoice.expiration_date).toLocaleDateString() : '-'}</span>
                                </div>
                            </div>
                            <div className="details-row">
                                <div className="details-group">
                                    <label>Número NF</label>
                                    <span>{invoice.nf_number || '-'}</span>
                                </div>
                                <div className="details-group">
                                    <label>Fornecedor</label>
                                    <span>{invoice.supplier || '-'}</span>
                                </div>
                            </div>
                            
                            <div className="details-row">
                                <div className="details-group">
                                    <label>Custo de Entrega</label>
                                    <span>R$ {invoice.shipping ? invoice.shipping.toFixed(2) : '0.00'}</span>
                                </div>
                                <div className="details-group">
                                    <label>Taxas</label>

                                    <span>R$ {invoice.taxes ? invoice.taxes.toFixed(2) : '0.00'}</span>
                                </div>
                            </div>


                            <div className="details-row">
                                <div className="details-group">
                                    <label>Status</label>
                                    <span>{invoice.status || '-'}</span>
                                </div>
                                <div className="details-group">
                                    <label>Valor Total</label>
                                    <span className="total-value">R$ {invoice.total_value ? invoice.total_value.toFixed(2) : '0.00'}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="items-section">
                        <h3>Itens da Nota</h3>
                        <table>
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Descrição</th>
                                    <th>Quantidade</th>
                                    <th>Preço Unit.</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoice.items?.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.product_id}</td>
                                        <td>{item.description}</td>
                                        <td>{item.quantity}</td>
                                        <td>R$ {item.price.toFixed(2)}</td>
                                        <td>R$ {(item.quantity * item.price).toFixed(2)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InvoiceInfoModal; 