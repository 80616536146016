import React, { useState, useEffect } from 'react';
import { updateProduct } from '../../api';
import '../Estoque.css';
import { FaTrash } from 'react-icons/fa';

function EditProductModal({ show, onClose, onSubmit, product }) {
    const [formData, setFormData] = useState({
        product_id: '',
        description: '',
        ideal: 0,
        min: 0,
        price: 0,
        qty: 0
    });
    const [error, setError] = useState('');
    const [processing, setProcessing] = useState(false);

    // Initialize form data when product changes
    useEffect(() => {
        if (product) {
            setFormData({
                product_id: product.product_id || '',
                description: product.description || '',
                ideal: product.ideal || 0,
                min: product.min || 0,
                price: product.price || 0,
                qty: product.qty || 0
            });
        }
    }, [product]);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (show && e.key === 'Escape') {
                onClose();
            }
        };

        if (show) {
            document.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [show, onClose]);

    if (!show) return null;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: name === 'description' ? value : Number(value)
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setProcessing(true);
            setError('');
            
            const result = await updateProduct(formData);
            onSubmit(result.product);
            onClose();
        } catch (err) {
            setError(err.message || 'Error updating product');
        } finally {
            setProcessing(false);
        }
    };

    const handleDelete = async () => {
        if (window.confirm('Tem certeza que deseja deletar este produto? Esta ação não pode ser desfeita.')) {
            try {
                setProcessing(true);
                setError('');
                
                await updateProduct({
                    product_id: formData.product_id,
                    action: 'delete'
                });
                
                onSubmit();
                onClose();
            } catch (err) {
                setError(err.message || 'Error deleting product');
            } finally {
                setProcessing(false);
            }
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Editar Produto</h2>
                {error && <div className="error-message">{error}</div>}
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Descrição:</label>
                        <input
                            type="text"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Quantidade Ideal:</label>
                        <input
                            type="number"
                            name="ideal"
                            value={formData.ideal}
                            onChange={handleChange}
                            min="0"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Quantidade Mínima:</label>
                        <input
                            type="number"
                            name="min"
                            value={formData.min}
                            onChange={handleChange}
                            min="0"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Preço:</label>
                        <input
                            type="number"
                            name="price"
                            value={formData.price}
                            onChange={handleChange}
                            min="0"
                            step="0.01"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Quantidade Atual:</label>
                        <input
                            type="number"
                            name="qty"
                            value={formData.qty}
                            onChange={handleChange}
                            min="0"
                            required
                        />
                    </div>
                    <div className="form-actions">
                        <button 
                            type="button" 
                            className="delete-button"
                            onClick={handleDelete}
                            disabled={processing}
                        >
                            <FaTrash /> Deletar
                        </button>
                        <div>
                            <button type="button" onClick={onClose} className="cancel-button">
                                Cancelar
                            </button>
                            <button type="submit" className="submit-button" disabled={processing}>
                                {processing ? 'Salvando...' : 'Salvar'}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default EditProductModal; 