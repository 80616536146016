import React from 'react';
import { ClipLoader } from 'react-spinners';
import { FaPlus, FaTimes } from 'react-icons/fa';

function PagamentosTab({
  paymentStartDate,
  setPaymentStartDate,
  paymentEndDate,
  setPaymentEndDate,
  paymentStatus,
  setPaymentStatus,
  showPaymentModal,
  setShowPaymentModal,
  showCommissionModal,
  setShowCommissionModal,
  invoicesError,
  paymentsLoading,
  payments,
  handleDeletePayment,
  setSelectedPayment,
  setShowPaymentInfoModal
}) {
  return (
    <div className="pagamentos-container">
      <div className="table-actions">
        <button className="success" onClick={() => setShowPaymentModal(true)}>
          <FaPlus /> Adicionar Pagamento
        </button>
        <button className="success" onClick={() => setShowCommissionModal(true)}>
          Lançar Comissões
        </button>
      </div>
      <div className="caixa-search-filters">
        <div className="date-filters">
          <div className="date-input-group">
            <label>Data Inicial:</label>
            <input
              type="date"
              value={paymentStartDate}
              onChange={(e) => setPaymentStartDate(e.target.value)}
              className="date-input"
              required
              onClick={(e) => e.target.showPicker()}
            />
          </div>
          <div className="date-input-group">
            <label>Data Final:</label>
            <input
              type="date"
              value={paymentEndDate}
              onChange={(e) => setPaymentEndDate(e.target.value)}
              className="date-input"
              required
              onClick={(e) => e.target.showPicker()}
            />
          </div>
          <div className="date-input-group">
            <label>Status:</label>
            <select
              value={paymentStatus}
              onChange={(e) => setPaymentStatus(e.target.value)}
              className="status-select"
            >
              <option value="sent">Aberta</option>
              <option value="paid">Pago</option>
            </select>
          </div>
        </div>
      </div>

      {invoicesError && <div className="error-message">{invoicesError}</div>}
      {paymentsLoading ? (
        <div className="loading-container">
          <ClipLoader size={50} color={"#4a90e2"} />
        </div>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Data de vencimento</th>
              <th>Descrição</th>
              <th>Fornecedor</th>
              <th>Valor Total</th>
              <th>Deletar</th>
            </tr>
          </thead>
          <tbody>
            {payments.length === 0 ? <tr><td colSpan="6">No payments found</td></tr> : payments.map((payment) => (
              <tr 
                key={payment.payment_id}
                className="clickable-row"
                onClick={() => {
                  setSelectedPayment(payment);
                  setShowPaymentInfoModal(true);
                }}
              >
                <td>{payment.expiration_date ? payment.expiration_date.split('T')[0].split('-').reverse().join('/') : '-'}</td>
                <td>{payment.description || '-'}</td>
                <td>{payment.supplier || '-'}</td>
                <td>R$ {payment.amount ? parseFloat(payment.amount).toFixed(2) : '0.00'}</td>
                <td>
                  <button 
                    className="delete-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeletePayment(payment.payment_id);
                    }}
                  >
                    <FaTimes />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default PagamentosTab; 