import React, { useState, useEffect, useRef } from 'react';
import PacientSearchSelect from '../../PacientSearchSelect/PacientSearchSelect';
import { fetchProcedimentos, createBooking, fetchBookings } from '../../api';
import { ClipLoader } from 'react-spinners';

function CreateBooking({ professionals, rooms }) {
  const [selectedProfessional, setSelectedProfessional] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState('30');
  const [selectedHour, setSelectedHour] = useState('');
  const [selectedMinute, setSelectedMinute] = useState('');
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [procedures, setProcedures] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedProcedures, setSelectedProcedures] = useState([]);
  const [showSubProcedures, setShowSubProcedures] = useState(false);
  const [currentProcedure, setCurrentProcedure] = useState(null);
  const [generalObservation, setGeneralObservation] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showMissingFieldsModal, setShowMissingFieldsModal] = useState(false);
  const [missingFields, setMissingFields] = useState([]);
  const [patientLastBookings, setPatientLastBookings] = useState([]);
  const [patientBookingsLoading, setPatientBookingsLoading] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [roomBookings, setRoomBookings] = useState([]);
  const [roomBookingsLoading, setRoomBookingsLoading] = useState(false);
  
  // Ref for patient search component
  const patientSearchRef = useRef(null);

  useEffect(() => {
    fetchProcedimentos()
      .then(data => {
        const proceduresList = Object.entries(data.config.procedimentos).map(([key, value]) => ({
          name: key,
          ...value  
        }));
        setProcedures(proceduresList);
      })
      .catch(error => console.error('Error fetching procedures:', error));
  }, []);

  // Load room bookings when a room is selected or date changes
  useEffect(() => {
    if (selectedRoom?.id && selectedDate) {
      loadRoomBookings(selectedRoom.id, selectedDate);
    } else {
      setRoomBookings([]);
    }
  }, [selectedRoom, selectedDate]);

  const loadRoomBookings = async (roomId, date) => {
    try {
      setRoomBookingsLoading(true);
      
      // Create query params
      const queryParams = {
        room_id: roomId,
        date: date,
        specified_index: "room"
      };
      
      // Fetch bookings
      const bookingsData = await fetchBookings(queryParams);
      
      // Filter out duplicates by atendimento_id and cancelled bookings
      const uniqueBookings = [];
      const bookingIds = new Set();
      
      bookingsData
        .filter(booking => booking.status !== 'Cancelado') // Filter out cancelled bookings
        .forEach(booking => {
          if (booking.atendimento_id && !bookingIds.has(booking.atendimento_id)) {
            bookingIds.add(booking.atendimento_id);
            uniqueBookings.push(booking);
          } else if (!booking.atendimento_id) {
            // If no ID, use combination of time and patient as key
            const bookingKey = `${booking.datetimeAtendimento}-${booking.paciente}`;
            if (!bookingIds.has(bookingKey)) {
              bookingIds.add(bookingKey);
              uniqueBookings.push(booking);
            }
          }
        });
      
      setRoomBookings(uniqueBookings);
      console.log("Room bookings:", uniqueBookings);
    } catch (error) {
      console.error('Error fetching room bookings:', error);
    } finally {
      setRoomBookingsLoading(false);
    }
  };

  const handlePatientSelect = (patient) => {
    console.log(patient);
    setSelectedPatient(patient);
    
    // Fetch last 3 bookings for this patient
    if (patient && patient.pacientId) {
      fetchPatientLastBookings(patient.pacientId);
    }
  };

  // Function to fetch patient's last bookings
  const fetchPatientLastBookings = async (patientId) => {
    try {
      setPatientBookingsLoading(true);
      // Get today's date
      const today = new Date().toISOString().split('T')[0];
      
      // Create query params
      const queryParams = {
        patientId: patientId,
        endDate: today
      };
      
      // Fetch bookings
      const bookingsData = await fetchBookings(queryParams);
      
      // Filter out cancelled bookings and get the last 3
      const filteredBookings = bookingsData
        .filter(booking => booking.status !== 'Cancelado')
        .sort((a, b) => new Date(b.datetimeAtendimento) - new Date(a.datetimeAtendimento))
        .slice(0, 3);
      
      setPatientLastBookings(filteredBookings);
    } catch (error) {
      console.error('Error fetching patient bookings:', error);
    } finally {
      setPatientBookingsLoading(false);
    }
  };

  const handleProcedureSelect = (procedure) => {
    if (procedure.subcategorias) {
      setCurrentProcedure(procedure);
      setShowSubProcedures(true);
    } else {
      addProcedure(procedure, null, { valor: procedure.valor });
    }
  };

  const handleSubProcedureSelect = (subProcedureName, subProcedure) => {
    addProcedure(currentProcedure, subProcedureName, subProcedure);
    setShowSubProcedures(false);
    setCurrentProcedure(null);
  };

  const addProcedure = (procedure, subProcedureName, subProcedure) => {
    const procedureName = typeof procedure === 'string' ? procedure : procedure.name;
    
    const value = subProcedure.valor || 0;
    
    let commission = null;
    if (subProcedure && 'comission' in subProcedure) {
      commission = subProcedure.comission;
    } else if (procedure && 'comission' in procedure) {
      commission = procedure.comission;
    }
    
    const newProcedure = {
      procedure: procedureName,
      subProcedure: subProcedureName,
      value: value,
      commission: commission
    };
    
    console.log('New procedure:', newProcedure);
    setSelectedProcedures([...selectedProcedures, newProcedure]);
  };

  const updateProcedure = (index, field, value) => {
    const updatedProcedures = [...selectedProcedures];
    updatedProcedures[index] = {
      ...updatedProcedures[index],
      [field]: value
    };
    setSelectedProcedures(updatedProcedures);
  };

  const removeProcedure = (index) => {
    const updatedProcedures = selectedProcedures.filter((_, i) => i !== index);
    setSelectedProcedures(updatedProcedures);
  };

  const renderSelectedProcedures = () => {
    return selectedProcedures.map((proc, index) => (
      <div key={index} className="selected-procedure-item">
        <div className="procedure-details">
          <div className="procedure-name">
            <span>{proc.procedure}</span>
            {proc.subProcedure && <span className="subprocedure-name"> - {proc.subProcedure}</span>}
          </div>
          
          <div className="procedure-value-edit">
            <label>Valor R$:</label>
            <input 
              type="number" 
              value={proc.value} 
              onChange={(e) => {
                // Replace any commas with periods
                const value = e.target.value.replace(',', '.');
                updateProcedure(index, 'value', parseFloat(value) || 0);
              }}
              step="0.01"
              min="0"
            />
            <div className="procedure-actions">
              <button 
                className="discount-button"
                onClick={(e) => {
                  e.stopPropagation();
                  const discountOverlay = document.getElementById(`discount-overlay-${index}`);
                  if (discountOverlay) {
                    discountOverlay.style.display = discountOverlay.style.display === 'none' ? 'flex' : 'none';
                  }
                }}
              >
                <span>%</span>
              </button>
              <button 
                className="remove-button" 
                onClick={() => removeProcedure(index)}
              >
                ×
              </button>
            </div>
            <div id={`discount-overlay-${index}`} className="discount-overlay" style={{ display: 'none' }}>
              <div className="discount-overlay-header">Aplicar desconto</div>
              {[10, 15, 20, 25].map((discount) => (
                <button
                  key={discount}
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    const originalValue = parseFloat(proc.value);
                    const discountedValue = originalValue * (1 - discount / 100);
                    updateProcedure(index, 'value', parseFloat(discountedValue.toFixed(2)));
                    document.getElementById(`discount-overlay-${index}`).style.display = 'none';
                  }}
                >
                  {discount}%
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    ));
  };

  const filteredProcedures = procedures.filter(proc => 
    proc.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const checkMissingFields = () => {
    const missing = [];
    if (!selectedProfessional) missing.push('Profissional');
    if (!selectedDate) missing.push('Data');
    if (!selectedHour || !selectedMinute) missing.push('Horário');
    if (!selectedDuration) missing.push('Duração');
    if (!selectedPatient) missing.push('Paciente');
    if (selectedProcedures.length === 0) missing.push('Procedimentos');
    return missing;
  };

  const handleBooking = async () => {
    const missingFieldsList = checkMissingFields();
    
    if (missingFieldsList.length > 0) {
      setMissingFields(missingFieldsList);
      setShowMissingFieldsModal(true);
      setTimeout(() => setShowMissingFieldsModal(false), 3000);
      return;
    }
    
    const formattedTime = `${selectedHour.padStart(2, '0')}:${selectedMinute.padStart(2, '0')}`;
    const bookingData = {
      professionalId: selectedProfessional.id,
      patientId: selectedPatient.pacientId,
      dataAtendimento: `${selectedDate}T${formattedTime}:00Z`,
      duration: parseInt(selectedDuration),
      procedimentos: selectedProcedures.map(proc => ({
        nome: proc.subProcedure ? `${proc.procedure} - ${proc.subProcedure}` : proc.procedure,
        valor: proc.value,
        commission: proc.commission
      })),
      observacao: generalObservation,
      room_id: selectedRoom?.id
    };

    try {
      setIsLoading(true);
      const result = await createBooking(bookingData);
      setSuccessMessage('Agendamento criado com sucesso!');
      resetForm();
    } catch (error) {
      console.error('Booking creation failed:', error);
      setSuccessMessage(`Erro ao criar agendamento: ${error.message || 'Por favor, tente novamente.'}`);
    } finally {
      setIsLoading(false);
      setTimeout(() => setSuccessMessage(''), 5000);
    }
  };

  const resetForm = () => {
    setSelectedProfessional(null);
    setSelectedDate(null);
    setSelectedHour('');
    setSelectedMinute('');
    setSelectedDuration('30');
    setSelectedPatient(null);
    setSelectedProcedures([]);
    setGeneralObservation('');
    setSearchTerm('');
    setPatientLastBookings([]);
    setSelectedRoom(null);
    
    const dateInput = document.querySelector('input[type="date"]');
    if (dateInput) {
      dateInput.value = '';
    }

    if (patientSearchRef.current) {
      patientSearchRef.current.resetSearch();
    }
  };

  const MissingFieldsModal = () => {
    if (!showMissingFieldsModal) return null;

    return (
      <div className="modal-overlay" onClick={() => setShowMissingFieldsModal(false)}>
        <div className="modal-content" onClick={e => e.stopPropagation()}>
          <button className="close-button" onClick={() => setShowMissingFieldsModal(false)}>×</button>
          <h3>Campos Obrigatórios</h3>
          <p>Por favor, preencha os campos a seguir para poder agendar:</p>
          <ul>
            {missingFields.map((field, index) => (
              <li key={index}>{field}</li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  // Function to check if a time slot conflicts with existing bookings
  const checkTimeSlotConflicts = (startTime, duration, bookings) => {
    if (!startTime || !duration || !bookings.length) return false;
    
    // Parse the start time
    const [hours, minutes] = startTime.split(':').map(Number);
    const startMinutes = hours * 60 + minutes;
    const endMinutes = startMinutes + parseInt(duration);
    
    // Check for conflicts with existing bookings
    return bookings.some(booking => {
      if (!booking.datetimeAtendimento) return false;
      
      const bookingTime = booking.datetimeAtendimento.split('T')[1];
      const [bookingHours, bookingMinutes] = bookingTime.slice(0, 5).split(':').map(Number);
      const bookingStartMinutes = bookingHours * 60 + bookingMinutes;
      const bookingEndMinutes = bookingStartMinutes + (booking.duration || 30);
      
      // Check if time slots overlap
      return (
        (startMinutes < bookingEndMinutes && endMinutes > bookingStartMinutes) ||
        (bookingStartMinutes < endMinutes && bookingEndMinutes > startMinutes)
      );
    });
  };

  // Create a simulated booking based on current form selections
  const getSimulatedBooking = () => {
    if (!selectedDate || !selectedHour || !selectedMinute || !selectedDuration || !selectedRoom) {
      return null;
    }
    
    const formattedTime = `${selectedHour.padStart(2, '0')}:${selectedMinute.padStart(2, '0')}`;
    
    return {
      paciente: selectedPatient?.paciente || selectedPatient?.nome || "Agendamento",
      profissional: selectedProfessional?.id || "unknown",
      datetimeAtendimento: `${selectedDate}T${formattedTime}:00Z`,
      duration: parseInt(selectedDuration),
      isSimulated: true
    };
  };

  const simulatedBooking = getSimulatedBooking();
  const hasTimeConflict = simulatedBooking && 
    checkTimeSlotConflicts(
      `${selectedHour.padStart(2, '0')}:${selectedMinute.padStart(2, '0')}`, 
      selectedDuration, 
      roomBookings
    );

  return (
    <>
      <div className="booking-grid">
        <div className="booking-section">
          <h3>Escolha o profissional <span style={{color: 'red'}}>*</span></h3>
          <div className="professional-grid">
            {professionals.map(prof => (
              <div 
                key={prof.id} 
                className={`professional-card ${selectedProfessional?.id === prof.id ? 'selected' : ''}`}
                onClick={() => setSelectedProfessional(prof)}
              >
                <h4>{prof.name}</h4>
                <p>{prof.specialty}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="booking-section">
          <h3>Escolha o paciente <span style={{color: 'red'}}>*</span></h3>
          <PacientSearchSelect 
            ref={patientSearchRef}
            onPatientSelect={handlePatientSelect} 
          />
          
          {/* Display patient's last bookings */}
          {selectedPatient && patientLastBookings.length > 0 && (
            <div className="patient-last-bookings">
              <h4>Últimos atendimentos:</h4>
              <div className="last-bookings-list">
                {patientBookingsLoading ? (
                  <div className="loading-indicator">Carregando...</div>
                ) : (
                  patientLastBookings.map((booking, index) => (
                    <div 
                      key={index} 
                      className="last-booking-item"
                    >
                      {booking.procedimentos.map((proc, i) => (
                        <span key={i} className="last-booking-procedure">
                          {proc.nome}
                        </span>
                      ))}
                      <span className="last-booking-date">
                        {new Date(booking.datetimeAtendimento).toLocaleDateString()}
                      </span>
                    </div>
                  ))
                )}
              </div>
            </div>
          )}
        </div>

        <div className="booking-section">
          <h3>Escolha um ou mais procedimentos <span style={{color: 'red'}}>*</span></h3>
          <div className="procedure-search">
            <input
              type="text"
              placeholder="Buscar procedimentos..."
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
              className="procedure-search-input"
            />
          </div>
          
          {!showSubProcedures ? (
            <div className="procedure-list">
              {filteredProcedures.map((proc) => (
                <div 
                  key={proc.name}
                  className="procedure-item"
                  onClick={() => handleProcedureSelect(proc)}
                >
                  {proc.name}
                </div>
              ))}
            </div>
          ) : (
            <div className="subprocedure-list">
              <div className="subprocedure-header">
                <button onClick={() => setShowSubProcedures(false)}>Voltar</button>
                <h4>{currentProcedure.name}</h4>
              </div>
              {Object.entries(currentProcedure.subcategorias).map(([key, value]) => (
                <div 
                  key={key}
                  className="subprocedure-item"
                  onClick={() => handleSubProcedureSelect(key, value)}
                >
                  {key} - R$ {value.valor}
                </div>
              ))}
            </div>
          )}

          <div className="selected-procedures">
            <h4>Procedimentos Selecionados:</h4>
            {renderSelectedProcedures()}
          </div>
        </div>

        <div className="booking-section">
          <h3>Escolha a data e horário <span style={{color: 'red'}}>*</span></h3>
          <div className="date-time-container">
            <div 
              className="date-input-container"
              onClick={() => document.querySelector('.date-input').showPicker()}
            >
              <input 
                type="date" 
                className="date-input"
                onChange={(e) => setSelectedDate(e.target.value)}
              />
            </div>
            <div className="time-grid">
              <div className="time-selection-container">
                <div className="time-column">
                  <h4>Hora</h4>
                  <div className="time-scroll">
                    {[...Array(15)].map((_, i) => (
                      <div 
                        key={i} 
                        className={`time-item ${selectedHour === (i + 8).toString() ? 'selected' : ''}`}
                        onClick={() => setSelectedHour((i + 8).toString())}
                      >
                        {(i + 8).toString().padStart(2, '0')}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="time-column">
                  <h4>Minuto</h4>
                  <div className="time-scroll">
                    {[...Array(12)].map((_, i) => (
                      <div 
                        key={i} 
                        className={`time-item ${selectedMinute === (i * 5).toString() ? 'selected' : ''}`}
                        onClick={() => setSelectedMinute((i * 5).toString())}
                      >
                        {(i * 5).toString().padStart(2, '0')}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="duration-input">
              <label>Duração em minutos:</label>
              <input 
                type="number" 
                className="date-input"
                value={selectedDuration}
                onChange={(e) => {
                  // Replace any commas with periods
                  const value = e.target.value.replace(',', '.');
                  setSelectedDuration(value);
                }}
                placeholder="Tempo de duração"
                min="15"
                step="15"
                onKeyDown={(e) => {
                  // Prevent comma from being entered
                  if (e.key === ',') {
                    e.preventDefault();
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div className="booking-section">
          <h3>Escolha a sala</h3>
          <div className="room-grid">
            {rooms.map(room => (
              <div 
                key={room.id} 
                className={`room-card ${selectedRoom?.id === room.id ? 'selected' : ''}`}
                onClick={() => setSelectedRoom(room)}
              >
                <h4>{room.name}</h4>
                <p>{room.Descrição}</p>
              </div>
            ))}
          </div>
          
          {selectedRoom && selectedDate && (
            <div className="room-bookings">
              <h4>Agendamentos para {selectedRoom.name} em {selectedDate}:</h4>
              {roomBookingsLoading ? (
                <div className="loading-indicator">Carregando agendamentos...</div>
              ) : (
                <>
                  {/* Visual time slots calendar */}
                  <div className="room-time-slots">
                    <div className="time-slots-header">
                      <div className="time-slot-label">Horário</div>
                      <div className="time-slot-content">Agendamento</div>
                    </div>
                    <div className="time-slots-container">
                      {Array.from({length: 16}, (_, i) => i + 7).map(hour => {
                        const hourStr = hour.toString().padStart(2, '0');
                        const timeSlots = [0, 15, 30, 45].map(minute => {
                          const minuteStr = minute.toString().padStart(2, '0');
                          const timeStr = `${hourStr}:${minuteStr}`;
                          
                          // Find bookings that overlap with this time slot
                          const existingBookings = roomBookings.filter(booking => {
                            if (!booking) return false;
                            
                            const bookingTime = booking.datetimeAtendimento.split('T')[1];
                            const [bookingHours, bookingMinutes] = bookingTime.slice(0, 5).split(':').map(Number);
                            const bookingStartMinutes = bookingHours * 60 + bookingMinutes;
                            const bookingEndMinutes = bookingStartMinutes + (booking.duration || 30);
                            
                            const slotMinutes = hour * 60 + minute;
                            
                            return slotMinutes >= bookingStartMinutes && slotMinutes < bookingEndMinutes;
                          });
                          
                          // Check if the simulated booking overlaps with this time slot
                          let hasSimulated = false;
                          if (simulatedBooking) {
                            const bookingTime = simulatedBooking.datetimeAtendimento.split('T')[1];
                            const [bookingHours, bookingMinutes] = bookingTime.slice(0, 5).split(':').map(Number);
                            const bookingStartMinutes = bookingHours * 60 + bookingMinutes;
                            const bookingEndMinutes = bookingStartMinutes + (simulatedBooking.duration || 30);
                            
                            const slotMinutes = hour * 60 + minute;
                            
                            hasSimulated = slotMinutes >= bookingStartMinutes && slotMinutes < bookingEndMinutes;
                          }
                          
                          // Determine if there's a conflict (both existing and simulated)
                          const hasConflict = existingBookings.length > 0 && hasSimulated;
                          
                          // Determine class based on state
                          let timeSlotClass = 'time-slot';
                          if (hasConflict) {
                            timeSlotClass += ' conflict';
                          } else if (hasSimulated) {
                            timeSlotClass += ' simulated';
                          } else if (existingBookings.length > 0) {
                            timeSlotClass += ' occupied';
                          }
                          
                          if (selectedHour === hourStr && selectedMinute === minuteStr) {
                            timeSlotClass += ' selected';
                          }
                          
                          return (
                            <div 
                              key={timeStr} 
                              className={timeSlotClass}
                            >
                              <div className="time-slot-label">{timeStr}</div>
                              <div className="time-slot-content">
                                {existingBookings.map((booking, idx) => (
                                  <div 
                                    key={idx} 
                                    className="slot-booking existing"
                                    title={`${booking.paciente} - ${professionals.find(p => p.id === booking.profissional)?.name || 'Profissional'}`}
                                  >
                                    {booking.paciente}
                                  </div>
                                ))}
                                
                                {hasSimulated && (
                                  <div 
                                    className={`slot-booking simulated ${hasConflict ? 'conflict' : ''}`}
                                    title={`${selectedPatient?.paciente || 'Paciente'} - ${selectedProfessional?.name || 'Profissional'}`}
                                  >
                                    {hasConflict ? 'Conflito!' : 'Agendando agora'}
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        });
                        
                        return (
                          <div key={hour} className="hour-slots">
                            {timeSlots}
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  {hasTimeConflict && (
                    <div className="conflict-warning">
                      <p>⚠️ O horário selecionado conflita com outro agendamento nesta sala. Escolha outro horário ou sala.</p>
                    </div>
                  )}
                  
                  {roomBookings.length === 0 && !hasTimeConflict && (
                    <p>Nenhum agendamento para esta sala na data selecionada.</p>
                  )}
                </>
              )}
            </div>
          )}
          
          {selectedRoom && (!selectedDate || !selectedHour || !selectedMinute || !selectedDuration) && (
            <div className="room-booking-message">
              <p>Escolha a data e horário para verificar se há conflitos na sala</p>
            </div>
          )}
        </div>

        <div className="booking-section">
          <h3>Observação</h3>
          <textarea 
            className="date-input"
            value={generalObservation}
            onChange={(e) => setGeneralObservation(e.target.value)}
            placeholder="Observações gerais sobre o agendamento"
            rows="4"
          />
        </div>
      </div>

      <button 
        className="book-button"
        onClick={handleBooking}
      >
        {isLoading ? <ClipLoader size={20} color={"#ffffff"} /> : 'Confirmar Agendamento'}
      </button>

      {successMessage && (
        <div className={`success-message ${successMessage.includes('erro') ? 'error' : 'success'}`}>
          {successMessage}
        </div>
      )}
      
      <MissingFieldsModal />
    </>
  );
}

export default CreateBooking; 