import React, { useState, useEffect } from 'react';
import { fetchProcedimentos, updateProceduresConfig } from '../../api';

function ProceduresTab() {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [config, setConfig] = useState(null);
  const [expandedSections, setExpandedSections] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [statusMessage, setStatusMessage] = useState('');

  useEffect(() => {
    fetchProceduresConfig();
  }, []);

  const fetchProceduresConfig = async () => {
    try {
      setLoading(true);
      const data = await fetchProcedimentos();
      setConfig(data.config);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching config:', error);
      setStatusMessage('Erro ao carregar configurações');
      setLoading(false);
    }
  };

  const saveProceduresConfig = async () => {
    try {
      setSaving(true);
      setStatusMessage('Salvando...');
      
      await updateProceduresConfig(config);
      
      setStatusMessage('Configuração salva com sucesso!');
      setSaving(false);
      
      // Clear success message after 3 seconds
      setTimeout(() => setStatusMessage(''), 3000);
    } catch (error) {
      console.error('Error saving config:', error);
      setStatusMessage('Erro ao salvar configurações');
      setSaving(false);
    }
  };

  const updateProcedureValue = (procedureName, value) => {
    setConfig({
      ...config,
      procedimentos: {
        ...config.procedimentos,
        [procedureName]: {
          ...config.procedimentos[procedureName],
          valor: parseFloat(value) || 0
        }
      }
    });
  };

  const updateSubcategoryValue = (procedureName, subcategoryName, value) => {
    setConfig({
      ...config,
      procedimentos: {
        ...config.procedimentos,
        [procedureName]: {
          ...config.procedimentos[procedureName],
          subcategorias: {
            ...config.procedimentos[procedureName].subcategorias,
            [subcategoryName]: {
              ...config.procedimentos[procedureName].subcategorias[subcategoryName],
              valor: parseFloat(value) || 0
            }
          }
        }
      }
    });
  };

  const toggleSection = (name) => {
    setExpandedSections({
      ...expandedSections,
      [name]: !expandedSections[name]
    });
  };

  const addProcedure = () => {
    const name = prompt('Digite o nome do novo procedimento:');
    if (name && !config.procedimentos[name]) {
      setConfig({
        ...config,
        procedimentos: {
          ...config.procedimentos,
          [name]: { valor: 0 }
        }
      });
      setExpandedSections({
        ...expandedSections,
        [name]: true
      });
    } else if (config.procedimentos[name]) {
      alert('Já existe um procedimento com este nome');
    }
  };

  const addSubcategory = (procedureName) => {
    const name = prompt('Digite o nome da nova subcategoria:');
    if (name) {
      let updatedProc = { ...config.procedimentos[procedureName] };
      
      if (!updatedProc.subcategorias) {
        updatedProc.subcategorias = {};
      }
      
      if (updatedProc.subcategorias[name]) {
        alert('Já existe uma subcategoria com este nome');
        return;
      }
      
      updatedProc.subcategorias[name] = { valor: 0 };
      
      setConfig({
        ...config,
        procedimentos: {
          ...config.procedimentos,
          [procedureName]: updatedProc
        }
      });
    }
  };

  const removeProcedure = (procedureName) => {
    if (window.confirm(`Tem certeza que deseja remover o procedimento "${procedureName}"?`)) {
      const updatedProcedimentos = { ...config.procedimentos };
      delete updatedProcedimentos[procedureName];
      
      setConfig({
        ...config,
        procedimentos: updatedProcedimentos
      });
    }
  };

  const removeSubcategory = (procedureName, subcategoryName) => {
    if (window.confirm(`Tem certeza que deseja remover a subcategoria "${subcategoryName}"?`)) {
      const updatedSubcategorias = { ...config.procedimentos[procedureName].subcategorias };
      delete updatedSubcategorias[subcategoryName];
      
      setConfig({
        ...config,
        procedimentos: {
          ...config.procedimentos,
          [procedureName]: {
            ...config.procedimentos[procedureName],
            subcategorias: updatedSubcategorias
          }
        }
      });
    }
  };

  const filterProcedures = () => {
    if (!config || !config.procedimentos) return [];
    
    const searchLower = searchTerm.toLowerCase();
    return Object.keys(config.procedimentos).filter(name => 
      name.toLowerCase().includes(searchLower)
    );
  };

  if (loading) {
    return <div className="loading-message">Carregando configurações...</div>;
  }
      
  return (
    <div className="procedures-editor">
      <div className="procedures-header">
        <div className="search-container">
          <input
            type="text"
            placeholder="Buscar procedimentos..."
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            className="search-input"
          />
        </div>
        <div className="procedures-actions">
          <button onClick={addProcedure} className="action-button add-button">
            Adicionar Procedimento
          </button>
          <button 
            onClick={saveProceduresConfig}
            disabled={saving}
            className="action-button save-button"
          >
            {saving ? 'Salvando...' : 'Salvar Alterações'}
          </button>
        </div>
      </div>

      {statusMessage && (
        <div className={`status-message ${statusMessage.includes('Erro') ? 'error' : 'success'}`}>
          {statusMessage}
        </div>
      )}

      <div className="procedures-list">
        {filterProcedures().map(procName => (
          <div key={procName} className="procedure-item">
            <div 
              className="procedure-header"
              onClick={() => toggleSection(procName)}
              data-has-subcategories={config.procedimentos[procName].subcategorias ? "Expandir subcategorias" : ""}
            >
              <h3 className="procedure-name">
                <span className="dropdown-icon">
                  {expandedSections[procName] ? '▼' : '►'}
                </span>
                {procName}
              </h3>
              <div className="procedure-actions">
                {!config.procedimentos[procName].subcategorias && (
                  <div className="value-editor" onClick={(e) => e.stopPropagation()}>
                    <label>Valor:</label>
                    <input
                      type="number"
                      value={config.procedimentos[procName].valor || 0}
                      onChange={e => updateProcedureValue(procName, e.target.value)}
                      className="value-input"
                      onClick={(e) => e.stopPropagation()}
                    />
                  </div>
                )}
                <button 
                  onClick={(e) => {
                    e.stopPropagation();
                    addSubcategory(procName);
                  }}
                  className="action-button subcategory-button"
                >
                  <span className="button-icon">+</span>
                  Subcategoria
                </button>
              </div>
            </div>

            {expandedSections[procName] && config.procedimentos[procName].subcategorias && (
              <div className="subcategories-container">
                <h4>Subcategorias:</h4>
                <div className="subcategories-list">
                  {Object.keys(config.procedimentos[procName].subcategorias).map(subName => (
                    <div key={subName} className="subcategory-item">
                      <span className="subcategory-name">{subName}</span>
                      <div className="subcategory-actions">
                        <div className="value-editor" onClick={(e) => e.stopPropagation()}>
                          <label>Valor:</label>
                          <input
                            type="number"
                            value={config.procedimentos[procName].subcategorias[subName].valor || 0}
                            onChange={e => updateSubcategoryValue(procName, subName, e.target.value)}
                            className="value-input"
                            onClick={(e) => e.stopPropagation()}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProceduresTab; 