import React, { useState, useEffect } from 'react';
import { updateBooking, getFileFromS3 } from '../../api';

function ViewBookingModal({ booking, showModal, onClose, startDate, endDate, onUpdate }) {
  const [editedComission, setEditedComission] = useState(0);
  const [editedValorTotal, setEditedValorTotal] = useState(0);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    if (booking) {
      setEditedComission(booking.comission || 0);
      setEditedValorTotal(parseFloat(booking.valorTotal) || 0);
    }
  }, [booking]);

  useEffect(() => {
    if (showModal) {
      const handleEscapeKey = (event) => {
        if (event.key === 'Escape') {
          onClose();
        }
      };
      
      document.addEventListener('keydown', handleEscapeKey);
      return () => {
        document.removeEventListener('keydown', handleEscapeKey);
      };
    }
  }, [showModal, onClose]);

  const handleUpdateComission = async () => {
    try {
      setIsUpdating(true);
      await updateBooking({
        atendimento_id: booking.atendimento_id,
        comission: editedComission,
        valorTotal: editedValorTotal
      });
      onUpdate(); // Call the update callback
      onClose();
    } catch (error) {
      console.error('Error updating booking:', error);
      alert('Failed to update booking');
    } finally {
      setIsUpdating(false);
    }
  };

  const handleDownloadReceipt = async () => {
    try {
      setIsDownloading(true);
      await getFileFromS3(booking.file_key, true);
    } catch (error) {
      console.error('Error downloading receipt:', error);
      alert('Failed to download receipt');
    } finally {
      setIsDownloading(false);
    }
  };

  if (!showModal || !booking) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content dark" onClick={e => e.stopPropagation()}>
        <h2>Detalhes do Agendamento</h2>
        <div className="booking-details">
          <div className="form-group">
            <label>Paciente</label>
            <div className="detail-value">{booking.paciente}</div>
          </div>

          <div className="form-group">
            <label>Data e Hora</label>
            <div className="detail-value">
              {new Date(booking.dataAtendimento).toLocaleString()}
            </div>
          </div>

          <div className="form-group">
            <label>Duração (minutos)</label>
            <div className="detail-value">{booking.duration}</div>
          </div>

          <div className="form-group">
            <label>Procedimentos</label>
            <div className="detail-value">
              {booking.procedimentos?.map((proc, i) => (
                <div key={i}>{proc.nome}</div>
              ))}
            </div>
          </div>

          <div className="form-group">
            <label>Valor Total</label>
            <div className="detail-value editable">
              <input
                type="number"
                step="0.01"
                value={editedValorTotal}
                onChange={(e) => setEditedValorTotal(parseFloat(e.target.value) || 0)}
                className="commission-input"
              />
            </div>
          </div>

          <div className="form-group">
            <label>Comissão</label>
            <div className="detail-value editable">
              <input
                type="number"
                step="0.01"
                value={editedComission}
                onChange={(e) => setEditedComission(parseFloat(e.target.value) || 0)}
                className="commission-input"
              />
            </div>
          </div>

          <div className="form-group">
            <label>Status do Atendimento</label>
            <div className="detail-value">{booking.status || 'Agendado'}</div>
          </div>

          <div className="form-group">
            <label>Forma de Pagamento</label>
            <div className="detail-value">{booking.paymentMethod || 'Não definido'}</div>
          </div>

          <div className="form-group">
            <label>Status do Pagamento</label>
            <div className="detail-value">{booking.paymentStatus || 'Pendente'}</div>
          </div>

          {booking.paymentDate && (
            <div className="form-group">
              <label>Data do Pagamento</label>
              <div className="detail-value">
                {new Date(booking.paymentDate).toLocaleString()}
              </div>
            </div>
          )}

          {booking.observacao && (
            <div className="form-group">
              <label>Observação</label>
              <div className="detail-value">{booking.observacao}</div>
            </div>
          )}
        </div>

        <div className="modal-actions">
          <button 
            className="action-button update" 
            onClick={handleUpdateComission}
            disabled={isUpdating}
          >
            {isUpdating ? 'Atualizando...' : 'Atualizar Comissão'}
          </button>
          {booking.file_key && (
            <button 
              className="action-button" 
              onClick={handleDownloadReceipt}
              disabled={isDownloading}
            >
              {isDownloading ? 'Baixando...' : 'Baixar Comprovante'}
            </button>
          )}
          <button className="action-button" onClick={onClose}>
            Fechar
          </button>
        </div>
      </div>
    </div>
  );
}

export default ViewBookingModal; 