import React, { useEffect, useState } from 'react';
import { getFileFromS3, updatePayment } from '../api';
import { USER_GROUPS } from '../../config/permissions';
import { useAuth } from '../../hooks/useAuth';
import { FaFileUpload } from 'react-icons/fa';
import './Financeiro.css';

function PaymentInfoModal({ show, onClose, payment, onUpdate }) {
    const { user } = useAuth();
    const userGroups = user?.signInUserSession?.accessToken?.payload['cognito:groups'] || [];
    const isAdmin = userGroups.includes(USER_GROUPS.ADMIN);
    const [editedAmount, setEditedAmount] = useState('');
    const [editedExpirationDate, setEditedExpirationDate] = useState('');
    const [editedDescription, setEditedDescription] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [isEditingDate, setIsEditingDate] = useState(false);
    const [isEditingDescription, setIsEditingDescription] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [fileData, setFileData] = useState({
        file: null,
        file_name: ''
    });

    const getStatusText = (status) => {
        const statusMap = {
            'sent': 'Enviado',
            'paid': 'Pago'
        };
        return statusMap[status] || status;
    };

    useEffect(() => {
        if (payment?.amount) {
            setEditedAmount(parseFloat(payment.amount).toFixed(2));
        }
        if (payment?.expiration_date) {
            setEditedExpirationDate(payment.expiration_date.split('T')[0]);
        }
        if (payment?.description) {
            setEditedDescription(payment.description);
        }
    }, [payment]);

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };
        window.addEventListener('keydown', handleEsc);
        return () => window.removeEventListener('keydown', handleEsc);
    }, [onClose]);

    const handleMarkAsPaid = async () => {
        try {
            await updatePayment(payment.payment_id, { status: 'paid' });
            if (onUpdate) onUpdate();
            onClose();
        } catch (error) {
            console.error('Error marking payment as paid:', error);
            alert('Erro ao marcar pagamento como pago');
        }
    };

    const handleSaveChanges = async (field) => {
        setIsSaving(true);
        try {
            let updateData;
            
            if (field === 'amount') {
                updateData = { amount: editedAmount };
            } else if (field === 'date') {
                updateData = { expiration_date: editedExpirationDate };
            } else if (field === 'description') {
                updateData = { description: editedDescription };
            }
                
            await updatePayment(payment.payment_id, updateData);
            
            // Update the local payment object to reflect changes immediately
            if (field === 'amount') {
                payment.amount = editedAmount;
                setIsEditing(false);
            } else if (field === 'date') {
                payment.expiration_date = editedExpirationDate;
                setIsEditingDate(false);
            } else if (field === 'description') {
                payment.description = editedDescription;
                setIsEditingDescription(false);
            }
            
            if (onUpdate) onUpdate();
            
            setShowSuccess(true);
            setTimeout(() => {
                setShowSuccess(false);
            }, 3000);
        } catch (error) {
            console.error('Error updating payment:', error);
            alert('Erro ao atualizar o pagamento');
        } finally {
            setIsSaving(false);
        }
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64 = reader.result.split(',')[1];
                setFileData({
                    file: base64,
                    file_name: file.name
                });
            };
            reader.readAsDataURL(file);
        }
    };

    const handleUpdateFile = async () => {
        if (!fileData.file) return;
        
        setIsSaving(true);
        try {
            await updatePayment(payment.payment_id, {
                file: fileData.file,
                file_name: fileData.file_name
            });
            if (onUpdate) onUpdate();
            setShowSuccess(true);
            setFileData({ file: null, file_name: '' });
            setTimeout(() => {
                setShowSuccess(false);
            }, 3000);
        } catch (error) {
            console.error('Error updating payment file:', error);
            alert('Erro ao atualizar o arquivo');
        } finally {
            setIsSaving(false);
        }
    };

    if (!show) return null;

    const handleBackgroundClick = (e) => {
        if (e.target.className === 'modal-overlay') {
            onClose();
        }
    };

    const handleDownload = async () => {
        console.log(payment.file_key);
        console.log(payment);
        try {
            await getFileFromS3(payment.file_key, true);
        } catch (error) {
            console.error('Error downloading file:', error);
            alert('Erro ao baixar o arquivo');
        }
    };

    return (
        <div className="modal-overlay" onClick={handleBackgroundClick}>
            <div className="modal-container payment-modal">
                <div className="modal-header">
                    <h2>Detalhes do Pagamento</h2>
                    <button className="close-button" onClick={onClose}>&times;</button>
                </div>
                <div className="modal-body">
                    <div className="payment-details">
                        <div className="details-section">
                            <div className="details-row">
                                <div className="details-group">
                                    <label>Código de referência</label>
                                    <span className="reference-code">{payment.payment_id || '-'}</span>
                                </div>
                                {payment.file_key && (
                                    <div className="details-group">
                                        <button 
                                            className="download-button" 
                                            onClick={handleDownload}
                                        >
                                            Baixar comprovante
                                        </button>
                                    </div>
                                )}
                            </div>

                            <div className="details-row">
                                <div className="details-group">
                                    <label>Data de Criação</label>
                                    <span className="date-field">{payment.created_at ? new Date(payment.created_at).toLocaleDateString() : '-'}</span>
                                </div>
                                <div className="details-group">
                                    <label>Data de Vencimento</label>
                                    {isEditingDate ? (
                                        <div className="amount-edit-container">
                                            <input
                                                type="date"
                                                value={editedExpirationDate}
                                                onChange={(e) => setEditedExpirationDate(e.target.value)}
                                                className="amount-input"
                                                disabled={isSaving}
                                            />
                                            <button 
                                                className="save-button" 
                                                onClick={() => handleSaveChanges('date')}
                                                disabled={isSaving}
                                            >
                                                {isSaving ? 'Processando...' : 'Salvar'}
                                            </button>
                                        </div>
                                    ) : (
                                        <span 
                                            className="date-field editable"
                                            onClick={() => setIsEditingDate(true)}
                                        >
                                            {payment.expiration_date ? payment.expiration_date.split('T')[0].split('-').reverse().join('/') : '-'}
                                            <span className="edit-icon">✎</span>
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div className="details-row">
                                <div className="details-group">
                                    <label>Fornecedor</label>
                                    <span className="supplier-name">{payment.supplier_name || '-'}</span>
                                </div>
                                <div className="details-group">
                                    <label>Status</label>
                                    <span className={`status-badge ${payment.status}`}>{getStatusText(payment.status) || '-'}</span>
                                </div>
                            </div>

                            <div className="details-row">
                                <div className="details-group full-width">
                                    <label>Valor Total</label>
                                    <div className="amount-container">
                                        {isEditing ? (
                                            <div className="amount-edit-container">
                                                <input
                                                    type="number"
                                                    step="0.01"
                                                    value={editedAmount}
                                                    onChange={(e) => setEditedAmount(e.target.value)}
                                                    className="amount-input"
                                                    disabled={isSaving}
                                                />
                                                <button 
                                                    className="save-button" 
                                                    onClick={() => handleSaveChanges('amount')}
                                                    disabled={isSaving}
                                                >
                                                    {isSaving ? 'Processando...' : 'Salvar'}
                                                </button>
                                            </div>
                                        ) : (
                                            <span 
                                                className="amount-display editable"
                                                onClick={() => setIsEditing(true)}
                                            >
                                                R$ {editedAmount}
                                                <span className="edit-icon">✎</span>
                                            </span>
                                        )}
                                        {showSuccess && (
                                            <div className="success-message">
                                                Atualizado com sucesso!
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="details-row">
                                <div className="details-group full-width">
                                    <label>Descrição</label>
                                    {isEditingDescription ? (
                                        <div className="amount-edit-container description-edit-container">
                                            <textarea
                                                value={editedDescription}
                                                onChange={(e) => setEditedDescription(e.target.value)}
                                                className="description-input"
                                                rows="3"
                                                disabled={isSaving}
                                            />
                                            <button 
                                                className="save-button" 
                                                onClick={() => handleSaveChanges('description')}
                                                disabled={isSaving}
                                            >
                                                {isSaving ? 'Processando...' : 'Salvar'}
                                            </button>
                                        </div>
                                    ) : (
                                        <span 
                                            className="description-text editable"
                                            onClick={() => setIsEditingDescription(true)}
                                        >
                                            {payment.description || '-'}
                                            <span className="edit-icon">✎</span>
                                        </span>
                                    )}
                                </div>
                            </div>

                            <div className="details-row">
                                <div className="details-group full-width">
                                    <label>Observações</label>
                                    <span className="notes-text">{payment.notes || '-'}</span>
                                </div>
                            </div>

                            {isAdmin && payment.status !== 'paid' && (
                                <div className="details-row">
                                    <div className="details-group full-width">
                                        <button 
                                            className="mark-paid-button" 
                                            onClick={handleMarkAsPaid}
                                        >
                                            Marcar como pago
                                        </button>
                                    </div>
                                </div>
                            )}
                            
                            {isAdmin && payment.status === 'paid' && (
                                <div className="details-row">
                                    <div className="details-group full-width">
                                        <button 
                                            className="mark-open-button" 
                                            onClick={async () => {
                                                try {
                                                    await updatePayment(payment.payment_id, { status: 'sent' });
                                                    if (onUpdate) onUpdate();
                                                    onClose();
                                                } catch (error) {
                                                    console.error('Error marking payment as open:', error);
                                                    alert('Erro ao marcar pagamento como aberto');
                                                }
                                            }}
                                        >
                                            Marcar como Aberto
                                        </button>
                                    </div>
                                </div>
                            )}

                            <div className="details-row">
                                <div className="details-group full-width">
                                    <label>Anexo</label>
                                    <div className="file-actions">
                                        {payment.file_key ? (
                                            <>
                                                <button 
                                                    className="download-button" 
                                                    onClick={handleDownload}
                                                >
                                                    Baixar comprovante
                                                </button>
                                                <div className="file-upload-section">
                                                    <div className="file-upload-wrapper">
                                                        <input
                                                            type="file"
                                                            id="file-upload"
                                                            onChange={handleFileUpload}
                                                            accept=".pdf"
                                                            style={{ display: 'none' }}
                                                        />
                                                        <label htmlFor="file-upload" className="file-upload-label">
                                                            <FaFileUpload /> {fileData.file_name || 'Mudar anexo de pagamento'}
                                                        </label>
                                                    </div>
                                                    {fileData.file && (
                                                        <button 
                                                            className="save-button" 
                                                            onClick={handleUpdateFile}
                                                            disabled={isSaving}
                                                        >
                                                            {isSaving ? 'Processando...' : 'Salvar Anexo'}
                                                        </button>
                                                    )}
                                                </div>
                                            </>
                                        ) : (
                                            <div className="file-upload-section">
                                                <div className="file-upload-wrapper">
                                                    <input
                                                        type="file"
                                                        id="file-upload"
                                                        onChange={handleFileUpload}
                                                        accept=".pdf"
                                                        style={{ display: 'none' }}
                                                    />
                                                    <label htmlFor="file-upload" className="file-upload-label">
                                                        <FaFileUpload /> {fileData.file_name || 'Anexar pagamento'}
                                                    </label>
                                                </div>
                                                {fileData.file && (
                                                    <button 
                                                        className="save-button" 
                                                        onClick={handleUpdateFile}
                                                        disabled={isSaving}
                                                    >
                                                        {isSaving ? 'Processando...' : 'Salvar Anexo'}
                                                    </button>
                                                )}
                                            </div>
                                        )}
                                        {showSuccess && (
                                            <div className="success-message">
                                                Arquivo atualizado com sucesso!
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PaymentInfoModal; 