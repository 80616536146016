import React, { useState, useEffect } from 'react';
import './Estoque.css';
import { FaPlus, FaTimes, FaExclamationTriangle, FaEdit, FaFileDownload } from 'react-icons/fa';
import { 
    fetchStock, 
    getStockMovements, 
    fetchStockRequisitions, 
    createStockRequisition,
    createInvoice,
    fetchInvoices,
    updateStockRequisition,
    updateInvoice
} from '../api';
import StockModal from './Modals/StockModal';
import RequisitionModal from './Modals/RequisitionModal';
import InvoiceModal from './Modals/InvoiceModal';
import InvoiceInfoModal from './Modals/InvoiceInfoModal'; 
import ProductModal from './Modals/ProductModal';
import EditProductModal from './Modals/EditProductModal';
import SupplierModal from './Modals/SupplierModal';
import EditSupplierModal from './Modals/EditSupplierModal';
import SupplierSearchSelect from '../SupplierSearchSelect/SupplierSearchSelect';
import jsPDF from 'jspdf';
import { 
    StockTab, 
    MovementsTab, 
    RequisitionsTab, 
    InvoicesTab, 
    SuppliersTab 
} from './Tabs';

function Estoque() {
    const [activeTab, setActiveTab] = useState('stock');
    const [showModal, setShowModal] = useState(false);
    const [showRequisitionModal, setShowRequisitionModal] = useState(false);
    const [showInvoiceModal, setShowInvoiceModal] = useState(false);
    const [showInvoiceInfoModal, setShowInvoiceInfoModal] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [showProductModal, setShowProductModal] = useState(false);
    const [selectedRequisition, setSelectedRequisition] = useState(null);
    const [modalType, setModalType] = useState('add');
    const [stockItems, setStockItems] = useState([]);
    const [stockMovements, setStockMovements] = useState([]);
    const [requisitions, setRequisitions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [paginationToken, setPaginationToken] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [movementsLoading, setMovementsLoading] = useState(false);
    const [movementsError, setMovementsError] = useState(null);
    const [movementsPaginationToken, setMovementsPaginationToken] = useState(null);
    const [hasMoreMovements, setHasMoreMovements] = useState(true);
    const [requisitionsLoading, setRequisitionsLoading] = useState(false);
    const [requisitionsError, setRequisitionsError] = useState(null);
    const [requisitionsPaginationToken, setRequisitionsPaginationToken] = useState(null);
    const [hasMoreRequisitions, setHasMoreRequisitions] = useState(true);
    const [requisitionStatusFilter, setRequisitionStatusFilter] = useState('Aberta');
    const [searchTerm, setSearchTerm] = useState('');
    const [invoices, setInvoices] = useState([]);
    const [invoicesLoading, setInvoicesLoading] = useState(false);
    const [invoicesError, setInvoicesError] = useState(null);
    const [invoicesPaginationToken, setInvoicesPaginationToken] = useState(null);
    const [hasMoreInvoices, setHasMoreInvoices] = useState(true);
    const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
    const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
    const [invoiceStatus, setInvoiceStatus] = useState('sent');
    const [showSupplierModal, setShowSupplierModal] = useState(false);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [showEditProductModal, setShowEditProductModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showEditSupplierModal, setShowEditSupplierModal] = useState(false);


    const processStockItems = (items) => {
        return items.map(item => ({
            ...item,
            searchableText: item.description?.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') || ''
        }));
    };

    const loadStockData = async (token = null, useCache = true) => {
        try {
            if (!token && useCache) {
                const cachedData = localStorage.getItem('stockItemsCache');
                if (cachedData) {
                    const parsedData = JSON.parse(cachedData);
                    setStockItems(processStockItems(parsedData));
                    setHasMore(false);
                    return;
                }
            }

            setLoading(true);
            setError(null);

            if (!token) {
                let allItems = [];
                let nextToken = null;
                
                do {
                    const data = await fetchStock(nextToken);
                    allItems = [...allItems, ...data.items];
                    nextToken = data.paginationToken;
                } while (nextToken);

                const processedItems = processStockItems(allItems);
                setStockItems(processedItems);
                setHasMore(false);
                
                localStorage.setItem('stockItemsCache', JSON.stringify(allItems));
            } else {
                const data = await fetchStock(token);
                const processedItems = processStockItems(data.items);
                setStockItems(prev => [...prev, ...processedItems]);
                setHasMore(!!data.paginationToken);
                setPaginationToken(data.paginationToken);
            }
        } catch (err) {
            setError('Failed to load stock data');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const loadStockMovements = async (token = null) => {
        try {
            setMovementsLoading(true);
            setMovementsError(null);
            const data = await getStockMovements(token);
            
            if (token) {
                setStockMovements(prev => [...prev, ...data.items]);
            } else {
                setStockMovements(data.items);
            }
            
            setHasMoreMovements(!!data.paginationToken);
            setMovementsPaginationToken(data.paginationToken);
        } catch (err) {
            setMovementsError('Failed to load stock movements');
            console.error(err);
        } finally {
            setMovementsLoading(false);
        }
    };

    const loadRequisitions = async (token = null, useCache = true) => {
        try {
            console.log("loading requisitions", token, useCache);
            // Check cache first if useCache is true
            if (!token && useCache) {
                const cachedData = localStorage.getItem('stockRequisitionsCache');
                if (cachedData) {
                    console.log("using cached requisitions", cachedData);
                    const parsedData = JSON.parse(cachedData);
                    setRequisitions(parsedData);
                    setHasMoreRequisitions(false);
                    return;
                }
            }

            if (!useCache) {
                localStorage.removeItem('stockRequisitionsCache');
            }

            setRequisitionsLoading(true);
            setRequisitionsError(null);
            
            // If no token, we're starting fresh - let's get ALL pages

            if (!token) {
                let allRequisitions = [];
                let nextToken = null;
                
                do {
                    const data = await fetchStockRequisitions(nextToken);
                    allRequisitions = [...allRequisitions, ...data.items];
                    nextToken = data.paginationToken;
                } while (nextToken);

                setRequisitions(allRequisitions);
                setHasMoreRequisitions(false);
                
                // Cache the complete data
                localStorage.setItem('stockRequisitionsCache', JSON.stringify(allRequisitions));
            } else {
                // This branch is kept for compatibility but shouldn't be used anymore
                const data = await fetchStockRequisitions(token);
                setRequisitions(prev => [...prev, ...data.items]);
                setHasMoreRequisitions(!!data.paginationToken);
                setRequisitionsPaginationToken(data.paginationToken);
            }
        } catch (err) {
            setRequisitionsError('Failed to load requisitions');
            console.error(err);
        } finally {
            setRequisitionsLoading(false);
        }
    };

    const handleDeleteInvoice = async (invoiceId, createdAt) => {
        if (window.confirm('Quer mesmo deletar esta nota fiscal?')) {
            try {
                await updateInvoice(invoiceId, createdAt, { status: 'deleted' });
                // Refresh invoices after deletion
                await loadInvoices();
            } catch (err) {
                setInvoicesError('Falha ao deletar a nota fiscal');
                console.error(err);
            }
        }
    };

    const loadInvoices = async (token = null) => {
        try {
            setInvoicesLoading(true);
            setInvoicesError(null);
            const filters = {
                status: invoiceStatus,
                // Only show non-deleted invoices
                exclude_deleted: true
            };
            
            if (startDate) filters.startDate = startDate;
            if (endDate) filters.endDate = endDate;
            
            const data = await fetchInvoices(token, filters);
            
            if (token) {
                setInvoices(prev => [...prev, ...data.items]);
            } else {
                setInvoices(data.items);
            }
            
            setHasMoreInvoices(!!data.paginationToken);
            setInvoicesPaginationToken(data.paginationToken);
        } catch (err) {
            setInvoicesError('Failed to load invoices');
            console.error(err);
        } finally {
            setInvoicesLoading(false);
        }
    };

    useEffect(() => {
        loadStockData();
        loadStockMovements();
        loadRequisitions();
        loadInvoices();
    }, []);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleShowModal = (type) => {
        setModalType(type);
        setShowModal(true);
    };

    const handleCloseRequisitionModal = () => {
        setShowRequisitionModal(false);
        setSelectedRequisition(null);
    };

    const handleShowRequisitionModal = (requisition = null) => {
        setSelectedRequisition(requisition);
        setShowRequisitionModal(true);
    };

    const handleStockChange = async (stockChangeData) => {
        try {
            // Add the new movement to the list
            const newMovement = {
                ...stockChangeData,
                change_id: Date.now().toString(),
                timestamp: new Date().toISOString()
            };
            setStockMovements(prev => [newMovement, ...prev]);

            // Refresh both stock data and movements
            await Promise.all([
                loadStockData(),
                loadStockMovements()
            ]);
            handleCloseModal();
        } catch (err) {
            setError('Failed to process stock change');
            console.error(err);
        }
    };

    const handleCreateRequisition = async (requisitionData) => {
        try {
            await createStockRequisition(requisitionData);
            // Force reload without using cache after creating new requisition
            await loadRequisitions(null, false);
            handleCloseRequisitionModal();
        } catch (err) {
            setError('Failed to create requisition');
            console.error(err);
        }
    };

    const handleAttendRequisition = async (requisitionId, requisitionTimestamp) => {
        try {
            // Update the requisition first. This is not needed, because the backend function for this does both
            // await updateStockRequisition(requisitionId, requisitionTimestamp, { status: 'Fechado' });
            // Refresh all relevant data states
            await Promise.all([
                loadRequisitions(null, false),  // Refresh requisitions
                loadStockData(null, false),     // Refresh stock
                loadStockMovements()            // Refresh movements
            ]);
        } catch (err) {
            console.error(err);
        }
    };

    const handleCloseInvoiceModal = () => {
        setShowInvoiceModal(false);
    };

    const handleShowInvoiceModal = () => {
        setShowInvoiceModal(true);
    };

    const handleCreateInvoice = async (invoiceData) => {
        try {
            await createInvoice(invoiceData);
            await Promise.all([
                loadInvoices(),  // Refresh invoices
                loadStockData(null, false),  // Refresh stock
                loadStockMovements()  // Refresh movements
            ]);
            handleCloseInvoiceModal();
        } catch (err) {
            setError('Failed to create invoice');
            console.error(err);
        }
    };

    const handleCloseProductModal = () => {
        setShowProductModal(false);
    };

    const handleShowProductModal = () => {
        setShowProductModal(true);
    };

    const handleCreateProduct = async (productData) => {
        try {
            // Force reload without cache after creating a new product
            await loadStockData(null, false);
        } catch (err) {
            setError('Failed to refresh stock data');
            console.error(err);
        }
    };

    const handleCloseInvoiceInfoModal = () => {
        setShowInvoiceInfoModal(false);
        setSelectedInvoice(null);
    };

    const handleShowInvoiceInfoModal = (invoice) => {
        setSelectedInvoice(invoice);
        setShowInvoiceInfoModal(true);
    };

    const handleDeleteRequisition = async (requisitionId, timestamp) => {
        console.log("requisitionId", requisitionId);
        console.log("timestamp", timestamp);
        if (window.confirm('Quer mesmo deletar a requisição?')) {
            try {
                await updateStockRequisition(requisitionId, timestamp,{} , true);

                // Force reload without cache after deleting
                await loadRequisitions(null, false);
            } catch (err) {
                setError('Failed to delete requisition');
                console.error(err);
            }
        }
    };

    const handleCloseSupplierModal = () => {
        setShowSupplierModal(false);
    };

    const handleShowSupplierModal = () => {
        setShowSupplierModal(true);
    };

    const handleSupplierSelect = (supplier) => {
        setSelectedSupplier(supplier);
    };

    const handleCloseEditProductModal = () => {
        setShowEditProductModal(false);
        setSelectedProduct(null);
    };

    const handleShowEditProductModal = (product) => {
        setSelectedProduct(product);
        setShowEditProductModal(true);
    };

    const handleUpdateProduct = async (updatedProduct) => {
        try {
            // Force reload without cache after updating a product
            await loadStockData(null, false);
        } catch (err) {
            setError('Failed to refresh stock data');
            console.error(err);
        }
    };

    const handleCloseEditSupplierModal = () => {
        setShowEditSupplierModal(false);
    };

    const handleShowEditSupplierModal = () => {
        setShowEditSupplierModal(true);
    };

    const handleUpdateSupplier = (updatedSupplier) => {
        setSelectedSupplier(updatedSupplier);
    };

    return (
        <div className="estoque-container">
            <InvoiceInfoModal
                show={showInvoiceInfoModal}
                onClose={handleCloseInvoiceInfoModal}
                invoice={selectedInvoice}
            />
            <div className="estoque-tabs">
                <button 
                    className={`tab-button ${activeTab === 'stock' ? 'active' : ''}`}
                    onClick={() => setActiveTab('stock')}
                >
                    Estoque Atual
                </button>
                <button 
                    className={`tab-button ${activeTab === 'movements' ? 'active' : ''}`}
                    onClick={() => setActiveTab('movements')}
                >
                    Movimentações
                </button>
                <button 
                    className={`tab-button ${activeTab === 'requisitions' ? 'active' : ''}`}
                    onClick={() => setActiveTab('requisitions')}
                >
                    Requisições
                </button>
                <button 
                    className={`tab-button ${activeTab === 'invoices' ? 'active' : ''}`}
                    onClick={() => setActiveTab('invoices')}
                >
                    Entradas
                </button>
                <button 
                    className={`tab-button ${activeTab === 'suppliers' ? 'active' : ''}`}
                    onClick={() => setActiveTab('suppliers')}
                >
                    Fornecedores
                </button>
            </div>

            {activeTab === 'stock' && (
                <StockTab 
                    stockItems={stockItems}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    loading={loading}
                    error={error}
                    handleShowProductModal={handleShowProductModal}
                    handleShowEditProductModal={handleShowEditProductModal}
                />
            )}

            {activeTab === 'movements' && (
                <MovementsTab 
                    stockMovements={stockMovements}
                    movementsLoading={movementsLoading}
                    movementsError={movementsError}
                />
            )}

            {activeTab === 'requisitions' && (
                <RequisitionsTab 
                    requisitions={requisitions}
                    requisitionsLoading={requisitionsLoading}
                    requisitionsError={requisitionsError}
                    requisitionStatusFilter={requisitionStatusFilter}
                    setRequisitionStatusFilter={setRequisitionStatusFilter}
                    handleShowRequisitionModal={handleShowRequisitionModal}
                    handleDeleteRequisition={handleDeleteRequisition}
                />
            )}

            {activeTab === 'invoices' && (
                <InvoicesTab 
                    invoices={invoices}
                    invoicesLoading={invoicesLoading}
                    invoicesError={invoicesError}
                    hasMoreInvoices={hasMoreInvoices}
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    invoiceStatus={invoiceStatus}
                    setInvoiceStatus={setInvoiceStatus}
                    handleShowInvoiceModal={handleShowInvoiceModal}
                    handleShowInvoiceInfoModal={handleShowInvoiceInfoModal}
                    handleDeleteInvoice={handleDeleteInvoice}
                    loadInvoices={loadInvoices}
                    invoicesPaginationToken={invoicesPaginationToken}
                    setInvoices={setInvoices}
                    setInvoicesPaginationToken={setInvoicesPaginationToken}
                />
            )}

            {activeTab === 'suppliers' && (
                <SuppliersTab 
                    selectedSupplier={selectedSupplier}
                    handleSupplierSelect={handleSupplierSelect}
                    handleShowSupplierModal={handleShowSupplierModal}
                    handleShowEditSupplierModal={handleShowEditSupplierModal}
                />
            )}

            <StockModal
                show={showModal}
                onClose={handleCloseModal}
                type={modalType}
                onSubmit={handleStockChange}
            />

            <RequisitionModal
                show={showRequisitionModal}
                onClose={handleCloseRequisitionModal}
                requisition={selectedRequisition}
                onSubmit={handleCreateRequisition}
                onAttendRequisition={handleAttendRequisition}
            />

            <InvoiceModal
                show={showInvoiceModal}
                onClose={handleCloseInvoiceModal}
                onSubmit={handleCreateInvoice}
            />

            <ProductModal
                show={showProductModal}
                onClose={handleCloseProductModal}
                onSubmit={handleCreateProduct}
            />

            <EditProductModal
                show={showEditProductModal}
                onClose={handleCloseEditProductModal}
                onSubmit={handleUpdateProduct}
                product={selectedProduct}
            />

            <SupplierModal
                show={showSupplierModal}
                onClose={handleCloseSupplierModal}
            />

            <EditSupplierModal
                show={showEditSupplierModal}
                onClose={handleCloseEditSupplierModal}
                supplier={selectedSupplier}
                onUpdate={handleUpdateSupplier}
            />
        </div>
    );
}

export default Estoque; 