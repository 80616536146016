import React, { useState, useEffect } from 'react';
import PacientSearchSelect from '../../PacientSearchSelect/PacientSearchSelect';
import { fetchBookings, updateBooking } from '../../api';
import { FaCalendarAlt } from 'react-icons/fa';
import EditBookingModal from '../Modals/EditBookingModal';

function ViewBookings({ professionals, rooms }) {
  const [bookings, setBookings] = useState([]);
  const [bookingsLoading, setBookingsLoading] = useState(false);
  const [viewMode, setViewMode] = useState('table');
  const [searchProfessional, setSearchProfessional] = useState(null);
  const [searchPatient, setSearchPatient] = useState(null);
  const [searchDate, setSearchDate] = useState('');
  const [selectedStatuses, setSelectedStatuses] = useState(['Realizado', 'Agendado', "Chegou", "Confirmado"]);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editFormData, setEditFormData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Fetch initial bookings for today
    const today = new Date().toISOString().split('T')[0];
    setSearchDate(today);
    loadBookings({ date: today });
  }, []);

  const loadBookings = async (queryParams = {}) => {
    try {
      setBookingsLoading(true);
      const bookingsData = await fetchBookings(queryParams);
      setBookings(bookingsData);
    } catch (error) {
      console.error('Error fetching bookings:', error);
    } finally {
      setBookingsLoading(false);
    }
  };

  const handleSearch = () => {
    const queryParams = {};
    
    if (searchProfessional) {
      queryParams.professionalId = searchProfessional.id;
    }
    
    if (searchPatient) {
      queryParams.patientId = searchPatient.pacientId;
    }
    
    if (searchDate) {
      queryParams.date = searchDate;
    }

    loadBookings(queryParams);
  };

  const handleEditBooking = (booking) => {
    setSelectedBooking(booking);
    setEditFormData({
      title: booking.title,
      dataAtendimento: booking.datetimeAtendimento,
      duration: booking.duration,
      procedimentos: booking.procedimentos,
      procedimentosList: booking.procedimentosList || [],
      status: booking.status || 'Agendado',
      observacao: booking.observacao,
      valorTotal: booking.valorTotal,
      paymentMethod: booking.paymentMethod || '',
      paymentStatus: booking.paymentStatus || '',
      paymentDate: booking.paymentDate || booking.datetimeAtendimento
    });
    setShowEditModal(true);
  };

  const handleUpdateBooking = async (status) => {
    setIsLoading(true);
    try {
      const updates = {
        atendimento_id: selectedBooking.atendimento_id,
        ...editFormData,
        status: status || editFormData.status
      };
      
      await updateBooking(updates);
      setShowEditModal(false);
      handleSearch(); // Refresh the list
    } catch (error) {
      console.error('Error updating booking:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderBookingsList = () => {
    // Find professional name by ID
    const getProfessionalName = (professionalId) => {
      const professional = professionals.find(p => p.id === professionalId);
      return professional ? professional.name : professionalId;
    };

    const filteredBookings = bookings.filter(booking => 
      selectedStatuses.includes(booking.status || 'Agendado')
    );

    return (
      <div className="bookings-list">
        <h3>Agendamentos</h3>
        <div className="view-toggle-buttons">
          <button 
            className={`view-toggle-button ${viewMode === 'table' ? 'active' : ''}`}
            onClick={() => setViewMode('table')}
          >
            Tabela
          </button>
          <button 
            className={`view-toggle-button ${viewMode === 'calendar' ? 'active' : ''}`}
            onClick={() => setViewMode('calendar')}
          >
            Calendário
          </button>
        </div>
        
        {filteredBookings.length === 0 ? (
          <p>Nenhum agendamento encontrado.</p>
        ) : viewMode === 'table' ? (
          <table>
            <thead>
              <tr>
                <th>Paciente</th>
                <th>Profissional</th>
                <th>Data</th>
                <th>Horário</th>
                <th>Procedimentos</th>
                <th>Status</th>
                <th>Calendário</th>
              </tr>
            </thead>
            <tbody>
              {filteredBookings.map((booking, index) => {
                const timeOnly = booking.datetimeAtendimento ? 
                  booking.datetimeAtendimento.split('T')[1].slice(0, 5) : 
                  'N/A';

                return (
                  <tr 
                    key={index} 
                    onClick={() => handleEditBooking(booking)}
                    style={{ 
                      cursor: 'pointer',
                      backgroundColor: booking.status === 'Realizado' ? '#2e7d32' : 'inherit',
                      color: booking.status === 'Realizado' ? '#ffffff' : 'inherit'
                    }}
                  >
                    <td>{booking.paciente}</td>
                    <td>{getProfessionalName(booking.profissional)}</td>
                    <td>{booking.dataAtendimento}</td>
                    <td>{timeOnly}</td>
                    <td>
                      {booking.procedimentos.map((proc, i) => (
                        <div key={i}>{proc.nome}</div>
                      ))}
                    </td>
                    <td>{booking.status || 'Agendado'}</td>
                    <td onClick={(e) => {
                      e.stopPropagation();
                      booking.googleEventUrl && window.open(booking.googleEventUrl, '_blank');
                    }}>
                      {booking.googleEventUrl && (
                        <FaCalendarAlt 
                          style={{ 
                            cursor: 'pointer',
                            color: '#4a90e2'
                          }} 
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className="calendar-view">
            {renderCalendarView(filteredBookings)}
          </div>
        )}
      </div>
    );
  };

  const renderCalendarView = (bookings) => {
    // Group bookings by date and professional
    const bookingsByDate = {};
    
    bookings.forEach(booking => {
      if (!booking.datetimeAtendimento) return;
      
      const dateStr = booking.dataAtendimento;
      const professionalId = booking.profissional;
      
      if (!bookingsByDate[dateStr]) {
        bookingsByDate[dateStr] = {};
      }
      
      if (!bookingsByDate[dateStr][professionalId]) {
        bookingsByDate[dateStr][professionalId] = [];
      }
      
      bookingsByDate[dateStr][professionalId].push(booking);
    });

    // Return calendar view for the current date
    const dates = Object.keys(bookingsByDate).sort();
    
    if (dates.length === 0) {
      return <p>Nenhum agendamento encontrado.</p>;
    }

    // Helper function to detect if two appointments overlap
    const isOverlapping = (appt1, appt2) => {
      // Get start and end times
      const startTime1 = new Date(appt1.datetimeAtendimento).getTime();
      const endTime1 = startTime1 + (appt1.duration * 60 * 1000);
      
      const startTime2 = new Date(appt2.datetimeAtendimento).getTime();
      const endTime2 = startTime2 + (appt2.duration * 60 * 1000);
      
      // Check if they overlap
      return (startTime1 < endTime2 && startTime2 < endTime1);
    };

    // Helper function to group overlapping appointments
    const groupOverlappingAppointments = (appointments) => {
      if (!appointments.length) return [];
      
      // Sort appointments by start time
      const sorted = [...appointments].sort((a, b) => 
        new Date(a.datetimeAtendimento).getTime() - new Date(b.datetimeAtendimento).getTime()
      );
      
      const groups = [];
      let currentGroup = [sorted[0]];
      
      for (let i = 1; i < sorted.length; i++) {
        const currentAppt = sorted[i];
        let overlapsWithGroup = false;
        
        // Check if current appointment overlaps with any in the current group
        for (const groupAppt of currentGroup) {
          if (isOverlapping(currentAppt, groupAppt)) {
            overlapsWithGroup = true;
            break;
          }
        }
        
        if (overlapsWithGroup) {
          // Add to current group if overlapping
          currentGroup.push(currentAppt);
        } else {
          // Start a new group if not overlapping
          groups.push([...currentGroup]);
          currentGroup = [currentAppt];
        }
      }
      
      // Add the last group
      if (currentGroup.length) {
        groups.push(currentGroup);
      }
      
      return groups;
    };

    // Helper to calculate time range for a professional's bookings
    const calculateTimeRange = (bookings) => {
      if (!bookings || bookings.length === 0) return { start: 8, end: 9 }; // Default 1 hour range
      
      // Find earliest start time and latest end time
      let earliestHour = 24;
      let latestHour = 0;
      
      bookings.forEach(booking => {
        if (!booking.datetimeAtendimento) return;
        
        // Get start time
        const startTime = booking.datetimeAtendimento.split('T')[1];
        const hour = parseInt(startTime.slice(0, 2));
        const minute = parseInt(startTime.slice(3, 5));
        
        // Calculate end time
        const durationMinutes = booking.duration || 30; // Default to 30 if not specified
        const endHour = hour + Math.floor(durationMinutes / 60);
        const endMinute = minute + (durationMinutes % 60);
        const adjustedEndHour = endHour + (endMinute >= 60 ? 1 : 0);
        
        // Update earliest and latest
        earliestHour = Math.min(earliestHour, hour);
        latestHour = Math.max(latestHour, adjustedEndHour);
      });
      
      // Add padding (1 hour before and after)
      earliestHour = Math.max(0, earliestHour - 1);
      latestHour = Math.min(24, latestHour + 1);
      
      // Ensure we have at least a 2 hour window to make the display useful
      if (latestHour - earliestHour < 2) {
        latestHour = earliestHour + 2;
      }
      
      // Make sure latestHour is at least early hour + the duration of the longest appointment + 1
      const longestDuration = Math.max(...bookings.map(b => b.duration || 30)) / 60;
      latestHour = Math.max(latestHour, earliestHour + longestDuration + 1);
      
      // Clamp to valid hours (0-24)
      latestHour = Math.min(24, Math.ceil(latestHour));
      
      return { start: earliestHour, end: latestHour };
    };

    return (
      <div className="calendar-days">
        {dates.map(date => (
          <div key={date} className="calendar-day">
            <h4 className="calendar-date-header">{date}</h4>
            <div className="calendar-professionals">
              {Object.keys(bookingsByDate[date]).map(professionalId => {
                const professional = professionals.find(p => p.id === professionalId);
                const professionalName = professional ? professional.name : professionalId;
                
                // Group overlapping appointments
                const appointmentGroups = groupOverlappingAppointments(bookingsByDate[date][professionalId]);
                
                // Calculate time range for this professional's calendar
                const timeRange = calculateTimeRange(bookingsByDate[date][professionalId]);
                const totalHours = timeRange.end - timeRange.start;
                
                return (
                  <div key={professionalId} className="calendar-professional">
                    <h5 className="professional-name">{professionalName}</h5>
                    <div className="time-grid-container">
                      <div className="time-markers">
                        {Array.from({length: totalHours + 1}, (_, i) => i + timeRange.start).map(hour => (
                          <div 
                            key={hour} 
                            className="time-marker"
                            style={{ top: `${(hour - timeRange.start) * 100 / totalHours}%` }}
                          >
                            {hour}:00
                          </div>
                        ))}
                      </div>
                      <div className="appointment-grid" style={{ 
                        backgroundSize: `100% ${100 / totalHours}%`,
                        '--total-hours': totalHours
                      }}>
                        {appointmentGroups.map((group, groupIndex) => (
                          group.map((booking, bookingIndex) => {
                            // Calculate position and height based on time and duration
                            const startTime = booking.datetimeAtendimento.split('T')[1];
                            const hour = parseInt(startTime.slice(0, 2));
                            const minute = parseInt(startTime.slice(3, 5));
                            
                            // Position from top (in %)
                            const startPosition = ((hour - timeRange.start) * 60 + minute) / (totalHours * 60) * 100;
                            
                            // Height based on duration (in %)
                            const height = (booking.duration / (totalHours * 60)) * 100;
                            
                            // Status colors
                            const bgColor = booking.status === 'Realizado' ? '#2e7d32' : 
                                           booking.status === 'Chegou' ? '#1976d2' :
                                           booking.status === 'Cancelado' ? '#d32f2f' : '#7986cb';
                            
                            // Calculate width and left position based on group size
                            const width = group.length > 1 ? `${100 / group.length}%` : 'calc(100% - 10px)';
                            const left = group.length > 1 ? `${(bookingIndex * 100) / group.length}%` : '5px';
                            
                            return (
                              <div 
                                key={`${groupIndex}-${bookingIndex}`}
                                className="appointment-block"
                                style={{
                                  top: `${startPosition}%`,
                                  height: `${height}%`,
                                  backgroundColor: bgColor,
                                  width,
                                  left,
                                }}
                                onClick={() => handleEditBooking(booking)}
                              >
                                <div className="appointment-content">
                                  <strong>
                                    {booking.paciente} 
                                    {booking.procedimentos && booking.procedimentos.length > 0 && 
                                      ` - ${booking.procedimentos.map(p => p.nome).join(', ')}`
                                    }
                                  </strong>
                                </div>
                              </div>
                            );
                          })
                        ))}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="bookings-search-container">
      <div className="google-calendar-banner dark">
        <p>Também é possível ver os agendamentos no Calendário Google</p>
        <a href="https://calendar.google.com/calendar/u/0?cid=OWU0NmQ5NmEwMzZmMmE1MzU5NTc1MzUwNmI5ZmNhMWY2YjU5NzNkNDZmODVjZGVjZDdmMThkM2E1NTA4NGFjZEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t" target="_blank" rel="noopener noreferrer" className="google-calendar-button">
          Abrir Google Calendar
        </a>
      </div>
      <div className="bookings-search-filters">
        <div className="search-professional">
          <label>Profissional</label>
          <select 
            value={searchProfessional?.name || ''} 
            onChange={(e) => {
              const selected = professionals.find(p => p.name === e.target.value);
              setSearchProfessional(selected || null);
            }}
          >
            <option value="">Todos Profissionais</option>
            {professionals.map(prof => (
              <option key={prof.name} value={prof.name}>
                {prof.name}
              </option>
            ))}
          </select>
        </div>

        <div className="search-patient">
          <label>Paciente</label>
          <PacientSearchSelect 
            onPatientSelect={(patient) => setSearchPatient(patient)}
            placeholder="Buscar Paciente"
          />
        </div>

        <div className="search-date">
          <label>Data</label>
          <div 
            className="date-input-container"
            onClick={() => document.querySelector('.search-date input[type="date"]').showPicker()}
          >
            <input 
              type="date" 
              value={searchDate} 
              onChange={(e) => setSearchDate(e.target.value)}
              className="date-input"
            />
          </div>
        </div>

        <div className="search-status">
          <label>Status</label>
          <div className="status-checkboxes">
            {['Realizado', 'Agendado', "Chegou", "Confirmado", "Cancelado"].map(status => (
              <label key={status} className="status-checkbox">
                <input
                  type="checkbox"
                  checked={selectedStatuses.includes(status)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedStatuses([...selectedStatuses, status]);
                    } else {
                      setSelectedStatuses(selectedStatuses.filter(s => s !== status));
                    }
                  }}
                />
                {status}
              </label>
            ))}
          </div>
        </div>

        <button 
          className="search-button" 
          onClick={handleSearch}
        >
          Buscar
        </button>
      </div>
      {bookingsLoading ? (
        <div className="loading-message">Carregando agendamentos...</div>
      ) : (
        renderBookingsList()
      )}
      
      <EditBookingModal 
        showModal={showEditModal}
        formData={editFormData}
        onClose={() => setShowEditModal(false)}
        onUpdate={setEditFormData}
        onUpdateStatus={handleUpdateBooking}
        isLoading={isLoading}
      />
    </div>
  );
}

export default ViewBookings; 