import React, { useEffect, useCallback, useState } from 'react';
import '../Booking.css';
import Spinner from '../../Spinner/Spinner';
import { FaFileUpload } from 'react-icons/fa';
import { fetchProcedimentos } from '../../api';

function EditBookingModal({ 
  showModal, 
  formData, 
  onClose, 
  onUpdate, 
  onUpdateStatus,
  isLoading 
}) {
  const [procedures, setProcedures] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showSubProcedures, setShowSubProcedures] = useState(false);
  const [currentProcedure, setCurrentProcedure] = useState(null);

  useEffect(() => {
    if (formData?.procedimentosList) {
      const total = formData.procedimentosList.reduce((sum, proc) => sum + (parseFloat(proc.valor) || 0), 0);
      if (total !== formData.valorTotal) {
        onUpdate({
          ...formData,
          valorTotal: total
        });
      }
    }
  }, [formData?.procedimentosList]);

  console.log(formData);

  const handleEscapeKey = useCallback((event) => {
    if (event.key === 'Escape') {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    if (showModal) {
      document.addEventListener('keydown', handleEscapeKey);
      fetchProcedimentos()
        .then(data => {
          const proceduresList = Object.entries(data.config.procedimentos).map(([key, value]) => ({
            name: key,
            ...value  
          }));
          setProcedures(proceduresList);
        })
        .catch(error => console.error('Error fetching procedures:', error));

      return () => {
        document.removeEventListener('keydown', handleEscapeKey);
      };
    }
  }, [showModal, handleEscapeKey]);

  const handleProcedureSelect = (procedure) => {
    if (procedure.subcategorias) {
      setCurrentProcedure(procedure);
      setShowSubProcedures(true);
    } else {
      addProcedure(procedure.name, null, procedure.valor);
    }
  };

  const handleSubProcedureSelect = (subProcedureName, subProcedure) => {
    addProcedure(currentProcedure.name, subProcedureName, subProcedure.valor);
    setShowSubProcedures(false);
    setCurrentProcedure(null);
  };

  const addProcedure = (procedureName, subProcedureName, value) => {
    const newProcedure = {
      nome: subProcedureName ? `${procedureName} - ${subProcedureName}` : procedureName,
      valor: value
    };
    const updatedProcedures = [...(formData.procedimentosList || []), newProcedure];
    onUpdate({
      ...formData,
      procedimentosList: updatedProcedures,
      procedimentos: updatedProcedures.map(p => p.nome).join('|')
    });
  };

  const updateProcedure = (index, field, value) => {
    const updatedProcedures = [...(formData.procedimentosList || [])];
    updatedProcedures[index] = {
      ...updatedProcedures[index],
      [field]: value
    };
    onUpdate({
      ...formData,
      procedimentosList: updatedProcedures,
      procedimentos: updatedProcedures.map(p => p.nome).join('|')
    });
  };

  const removeProcedure = (index) => {
    const updatedProcedures = (formData.procedimentosList || []).filter((_, i) => i !== index);
    onUpdate({
      ...formData,
      procedimentosList: updatedProcedures,
      procedimentos: updatedProcedures.map(p => p.nome).join('|')
    });
  };

  const filteredProcedures = procedures.filter(proc => 
    proc.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCourtesyChange = (isCourtesy) => {
    if (isCourtesy) {
      onUpdate({
        ...formData,
        paymentMethod: 'Cortesia',
        paymentStatus: 'Realizado',
        comissionStatus: 'Realizado',
        comission: '0',
        valorTotal: 0,
        status: 'Realizado'
      });
    } else {
      // If Cortesia is unchecked, reset payment method
      onUpdate({
        ...formData,
        paymentMethod: '',
        paymentStatus: '',
        comissionStatus: '',
        // Keep other values
      });
    }
  };

  const isCortesiaSelected = formData && formData.paymentMethod === 'Cortesia';

  if (!showModal || !formData) return null;

  return (
    <div 
      className="modal-overlay"
    >
      <div 
        className="modal-content dark"
        onClick={e => e.stopPropagation()}
      >
        <h2>Editar Agendamento</h2>
        
        {isLoading ? (
          <div className="loading-container">
            <Spinner />
          </div>
        ) : (
          <>
            <div className="form-group">
              <label>Data e Hora</label>
              <input
                type="datetime-local"
                value={formData.dataAtendimento.slice(0, 16)}
                onChange={(e) => onUpdate({
                  ...formData,
                  dataAtendimento: e.target.value + ':00Z'
                })}
                className="dark-input"
                disabled={isLoading}
              />
            </div>

            <div className="form-group">
              <label>Duração (minutos)</label>
              <input
                type="number"
                value={formData.duration}
                onChange={(e) => {
                  const value = e.target.value.replace(',', '.');
                  onUpdate({
                    ...formData,
                    duration: value
                  });
                }}
                step="15"
                min="15"
                className="dark-input"
                disabled={isLoading}
                onKeyDown={(e) => {
                  if (e.key === ',') {
                    e.preventDefault();
                  }
                }}
              />
            </div>

            <div className="form-group">
              <h3>Procedimentos</h3>
              <div className="procedure-search">
                <input
                  type="text"
                  placeholder="Buscar procedimentos..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="procedure-search-input"
                />
              </div>
              
              {!showSubProcedures ? (
                <div className="procedure-list">
                  {filteredProcedures.map((proc) => (
                    <div 
                      key={proc.name}
                      className="procedure-item"
                      onClick={() => handleProcedureSelect(proc)}
                    >
                      {proc.name}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="subprocedure-list">
                  <div className="subprocedure-header">
                    <button onClick={() => setShowSubProcedures(false)}>Voltar</button>
                    <h4>{currentProcedure.name}</h4>
                  </div>
                  {Object.entries(currentProcedure.subcategorias).map(([key, value]) => (
                    <div 
                      key={key}
                      className="subprocedure-item"
                      onClick={() => handleSubProcedureSelect(key, value)}
                    >
                      {key} - R$ {value.valor}
                    </div>
                  ))}
                </div>
              )}

              <div className="selected-procedures">
                <h4>Procedimentos Selecionados:</h4>
                {(formData.procedimentosList || []).map((proc, index) => (
                  <div key={index} className="selected-procedure-item">
                    <div className="procedure-details">
                      <span>{proc.nome}</span>
                      <div className="procedure-value-edit">
                        <label>Valor R$:</label>
                        <input 
                          type="number" 
                          value={proc.valor} 
                          onChange={(e) => {
                            const value = e.target.value.replace(',', '.');
                            updateProcedure(index, 'valor', parseFloat(value) || 0);
                          }}
                          step="0.01"
                          min="0"
                          className="dark-input"
                          onKeyDown={(e) => {
                            if (e.key === ',') {
                              e.preventDefault();
                            }
                          }}
                          disabled={isCortesiaSelected}
                        />
                      </div>
                    </div>
                    <button onClick={() => removeProcedure(index)}>×</button>
                  </div>
                ))}
              </div>
            </div>

            <div className="form-group">
              <label>Valor Total (R$)</label>
              <input
                type="number"
                value={formData.valorTotal !== null && formData.valorTotal !== undefined ? formData.valorTotal : ''}
                onChange={(e) => {
                  const value = e.target.value.replace(',', '.');
                  onUpdate({
                    ...formData,
                    valorTotal: parseFloat(value) || 0
                  });
                }}
                step="0.01"
                min="0"
                className="dark-input"
                disabled={isLoading || isCortesiaSelected}
                onKeyDown={(e) => {
                  if (e.key === ',') {
                    e.preventDefault();
                  }
                }}
              />
            </div>

            <div className="form-group">
              <label>Modo de Pagamento</label>
              <div className="payment-methods-container">
                {['Pix', 'Crédito', 'Débito', 'Dinheiro', 'Cortesia', 'Plano de saúde'].map(method => (
                  <div key={method} className={`payment-method-option ${(formData.paymentMethod || '').split(', ').includes(method) ? 'selected' : ''}`}>
                    <input
                      type="checkbox"
                      id={`payment-${method}`}
                      checked={(formData.paymentMethod || '').split(', ').includes(method)}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        
                        if (method === 'Cortesia') {
                          handleCourtesyChange(isChecked);
                          return;
                        }

                        // For non-Cortesia methods
                        if (isCortesiaSelected && isChecked) {
                          // If cortesia is already selected and user tries to select another method
                          alert('Por favor, desmarque Cortesia antes de selecionar outro método de pagamento');
                          e.preventDefault();
                          return;
                        }

                        const currentMethods = (formData.paymentMethod || '').split(', ').filter(m => m !== '');
                        let newMethods;
                        
                        if (isChecked) {
                          newMethods = [...currentMethods, method];
                        } else {
                          newMethods = currentMethods.filter(m => m !== method);
                        }
                        
                        onUpdate({
                          ...formData,
                          paymentMethod: newMethods.join(', ')
                        });
                      }}
                      disabled={isLoading || (method !== 'Cortesia' && isCortesiaSelected)}
                    />
                    <label htmlFor={`payment-${method}`}>{method}</label>
                  </div>
                ))}
              </div>
            </div>
              <div className="form-group">
                <div className="checkbox-container">
                  <label htmlFor="patientConfirmed" className="checkbox-label">Confirmado por paciente?</label>
                  <div className="custom-checkbox">
                    <input
                      type="checkbox"
                      id="patientConfirmed"
                      checked={formData.status === 'Confirmado'}
                      onChange={(e) => onUpdate({
                        ...formData,
                        status: e.target.checked ? 'Confirmado' : 'Agendado'
                      })}
                      disabled={isLoading || formData.status === 'Chegou' || formData.status === 'Realizado' || isCortesiaSelected}
                    />
                    <span className="checkmark"></span>
                  </div>
                </div>
              </div>

            <div className="form-group">
              <div className="checkbox-container">
                <label htmlFor="patientArrived" className="checkbox-label">Paciente Chegou?</label>
                <div className="custom-checkbox">
                  <input
                    type="checkbox"
                    id="patientArrived"
                    checked={formData.status === 'Chegou' || formData.status === 'Realizado'}
                    onChange={(e) => onUpdate({
                      ...formData,
                      status: e.target.checked ? 'Chegou' : 'Agendado'
                    })}
                    disabled={isLoading || formData.status === 'Realizado' || isCortesiaSelected}
                  />
                  <span className="checkmark"></span>
                </div>
              </div>
            </div>


            <div className="form-group">
              <div className="checkbox-container">
                <label htmlFor="appointmentCompleted" className="checkbox-label">Atendimento Finalizado?</label>
                <div className="custom-checkbox">
                  <input
                    type="checkbox"
                    id="appointmentCompleted"
                    checked={formData.status === 'Realizado'}
                    onChange={(e) => onUpdate({
                      ...formData,
                      status: e.target.checked ? 'Realizado' : formData.status === 'Chegou' ? 'Chegou' : 'Agendado'
                    })}
                    disabled={isLoading || isCortesiaSelected}
                  />
                  <span className="checkmark"></span>
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="checkbox-container">
                <label htmlFor="paymentReceived" className="checkbox-label">Pagamento realizado?</label>
                <div className="custom-checkbox">
                  <input
                    type="checkbox"
                    id="paymentReceived"
                    checked={formData.paymentStatus === 'Realizado' || isCortesiaSelected}
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      if (isChecked && !formData.paymentMethod) {
                        alert('Por favor, selecione o método de pagamento');
                        e.preventDefault();
                        return;
                      }
                      onUpdate({
                        ...formData,
                        paymentStatus: isChecked ? 'Realizado' : '',
                        status: isChecked ? 'Realizado' : formData.status
                      });
                    }}
                    disabled={isLoading || isCortesiaSelected}
                  />
                  <span className="checkmark"></span>
                </div>
              </div>
            </div>

            {(formData.paymentStatus === 'Realizado' && !isCortesiaSelected) && (
              <>
                <div className="form-group">
                  <label>Data do Pagamento</label>
                  <input
                    type="datetime-local"
                    value={(formData.paymentDate || formData.dataAtendimento).slice(0, 16)}
                    onChange={(e) => onUpdate({
                      ...formData,
                      paymentDate: e.target.value + ':00Z'
                    })}
                    className="dark-input"
                    disabled={isLoading}
                  />
                </div>

                <div className="form-group">
                  <label>Comprovante de Pagamento:</label>
                  <div className="file-upload-wrapper">
                    <input
                      type="file"
                      id="payment-proof"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          const reader = new FileReader();
                          reader.onloadend = () => {
                            const base64 = reader.result.split(',')[1];
                            onUpdate({
                              ...formData,
                              file: base64,
                              file_name: file.name
                            });
                          };
                          reader.readAsDataURL(file);
                        }
                      }}
                      accept=".pdf, .jpg, .jpeg, .png"
                      style={{ display: 'none' }}
                      disabled={isLoading}
                    />
                    <label htmlFor="payment-proof" className="file-upload-label">
                      <FaFileUpload /> {formData.file_name || 'Anexar comprovante'}
                    </label>
                  </div>
                </div>
              </>
            )}

            <div className="form-group">
              <label>Observação</label>
              <textarea
                value={formData.observacao || ''}
                onChange={(e) => onUpdate({
                  ...formData,
                  observacao: e.target.value
                })}
                rows="4"
                className="dark-input"
                disabled={isLoading}
              />
            </div>

            <div className="modal-actions">
              <div className="left-buttons">
                <button 
                  className="action-button danger"
                  onClick={() => onUpdateStatus('Cancelado')}
                  disabled={isLoading}
                >
                  Cancelar agendamento
                </button>
              </div>
              <div className="right-buttons">
                <button 
                  className="action-button"
                  onClick={() => onUpdateStatus()}
                  disabled={isLoading}
                >
                  Salvar Alterações
                </button>
                <button 
                  className="action-button secondary"
                  onClick={onClose}
                  disabled={isLoading}
                >
                  Fechar
                </button>
              </div>
            </div>

            <style>
              {`
                .checkbox-container {
                  display: flex;
                  flex-direction: column;
                  gap: 8px;
                  align-items: flex-start;
                }

                .checkbox-label {
                  font-weight: 500;
                  color: #333;
                }

                .custom-checkbox {
                  position: relative;
                  display: inline-block;
                  cursor: pointer;
                }

                .custom-checkbox input {
                  position: absolute;
                  opacity: 0;
                  cursor: pointer;
                  height: 24px;
                  width: 24px;
                  top: 0;
                  left: 0;
                  margin: 0;
                  z-index: 1;
                }

                .checkmark {
                  position: relative;
                  display: inline-block;
                  height: 24px;
                  width: 24px;
                  background-color: #2c2c2c;
                  border: 2px solid #4a4a4a;
                  border-radius: 4px;
                  transition: all 0.2s ease;
                  pointer-events: none;
                }

                .custom-checkbox:hover .checkmark {
                  background-color: #3c3c3c;
                }

                .custom-checkbox input:checked ~ .checkmark {
                  background-color: #4a90e2;
                  border-color: #4a90e2;
                }

                .checkmark:after {
                  content: "";
                  position: absolute;
                  display: none;
                  left: 8px;
                  top: 4px;
                  width: 5px;
                  height: 10px;
                  border: solid white;
                  border-width: 0 2px 2px 0;
                  transform: rotate(45deg);
                }

                .custom-checkbox input:checked ~ .checkmark:after {
                  display: block;
                }

                .custom-checkbox input:disabled ~ .checkmark {
                  opacity: 0.5;
                  cursor: not-allowed;
                }

                .modal-actions {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-top: 20px;
                }

                .right-buttons {
                  display: flex;
                  gap: 10px;
                }

                .file-upload-wrapper {
                  margin-top: 8px;
                }

                .file-upload-label {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  padding: 8px 16px;
                  background-color: #2c2c2c;
                  border: 2px solid #4a4a4a;
                  border-radius: 4px;
                  cursor: pointer;
                  transition: all 0.2s ease;
                }

                .file-upload-label:hover {
                  background-color: #3c3c3c;
                }

                .file-upload-label svg {
                  font-size: 1.2em;
                }

                .payment-methods-container {
                  display: flex;
                  flex-wrap: wrap;
                  gap: 12px;
                  margin-top: 8px;
                }

                .payment-method-option {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  background-color: #2c2c2c;
                  border: 1px solid #4a4a4a;
                  border-radius: 8px;
                  padding: 10px 16px;
                  cursor: pointer;
                  transition: all 0.2s ease;
                  position: relative;
                  overflow: hidden;
                }

                .payment-method-option:hover {
                  background-color: #3c3c3c;
                  transform: translateY(-2px);
                  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
                }

                .payment-method-option.selected {
                  background-color: #2d4263;
                  border-color: #4a90e2;
                  box-shadow: 0 0 0 1px #4a90e2, 0 4px 8px rgba(0, 0, 0, 0.2);
                }

                .payment-method-option input[type="checkbox"] {
                  margin: 0;
                  accent-color: #4a90e2;
                  height: 18px;
                  width: 18px;
                }

                .payment-method-option label {
                  cursor: pointer;
                  margin: 0;
                  font-weight: 500;
                  color: #e0e0e0;
                  font-size: 14px;
                  letter-spacing: 0.3px;
                }

                .payment-method-option:disabled {
                  opacity: 0.6;
                  cursor: not-allowed;
                }
              `}
            </style>
          </>
        )}
      </div>
    </div>
  );
}

export default EditBookingModal; 