import React from 'react';
import { FaPlus, FaTimes } from 'react-icons/fa';

function InvoicesTab({ 
    invoices, 
    invoicesLoading, 
    invoicesError, 
    hasMoreInvoices, 
    startDate, 
    endDate, 
    setStartDate, 
    setEndDate, 
    invoiceStatus, 
    setInvoiceStatus, 
    handleShowInvoiceModal, 
    handleShowInvoiceInfoModal,
    handleDeleteInvoice,
    loadInvoices,
    invoicesPaginationToken,
    setInvoices,
    setInvoicesPaginationToken
}) {
    return (
        <>
            <div className="table-actions">
                <button className="success" onClick={handleShowInvoiceModal}>
                    <FaPlus /> Adicionar nota fiscal
                </button>
                <div className="date-filters">
                    <div className="date-input-group">
                        <label>Data Inicial:</label>
                        <input
                            type="date"
                            value={startDate}
                            onClick={(e) => e.target.showPicker()}
                            onChange={(e) => {
                                setStartDate(e.target.value);
                                setInvoices([]);
                                setInvoicesPaginationToken(null);
                            }}
                        />
                    </div>
                    <div className="date-input-group">
                        <label>Data Final:</label>
                        <input
                            type="date"
                            value={endDate}
                            onClick={(e) => e.target.showPicker()}
                            onChange={(e) => {
                                setEndDate(e.target.value);
                                setInvoices([]);
                                setInvoicesPaginationToken(null);
                            }}
                        />
                    </div>
                    <div className="date-input-group">
                        <label>Status:</label>
                        <select
                            value={invoiceStatus}
                            onChange={(e) => {
                                setInvoiceStatus(e.target.value);
                                setInvoices([]);
                                setInvoicesPaginationToken(null);
                            }}
                            className="status-select"
                        >
                            <option value="sent">Aberta</option>
                            <option value="paid">Paga</option>
                        </select>
                    </div>

                    <button 
                        className="dark-button"
                        onClick={() => loadInvoices()}
                        disabled={invoicesLoading}
                    >
                        Filtrar
                    </button>
                </div>
            </div>
            {invoicesError && <div className="error-message">{invoicesError}</div>}
            <table>
                <thead>
                    <tr>
                        <th>Data</th>
                        <th>Data Vencimento</th>
                        <th>Número NF</th>
                        <th>Fornecedor</th>
                        <th>Valor Total</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {invoices.length === 0 ? <tr><td colSpan="6">Nenhuma nota fiscal encontrada</td></tr> : invoices.map((invoice) => (
                        <tr 
                            key={invoice.invoice_id}
                            className="clickable-row"
                        >
                            <td onClick={() => handleShowInvoiceInfoModal(invoice)}>{new Date(invoice.created_at).toLocaleDateString()}</td>
                            <td onClick={() => handleShowInvoiceInfoModal(invoice)}>{invoice.expiration_date ? new Date(invoice.expiration_date).toLocaleDateString() : '-'}</td>
                            <td onClick={() => handleShowInvoiceInfoModal(invoice)}>{invoice.nf_number || '-'}</td>
                            <td onClick={() => handleShowInvoiceInfoModal(invoice)}>{invoice.supplier || '-'}</td>
                            <td onClick={() => handleShowInvoiceInfoModal(invoice)}>R$ {invoice.total_value ? invoice.total_value.toFixed(2) : '0.00'}</td>
                            <td>
                                <button 
                                    className="delete-button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDeleteInvoice(invoice.invoice_id, invoice.created_at);
                                    }}
                                    title="Deletar nota fiscal"
                                >
                                    <FaTimes />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {invoicesLoading && <div className="loading">Loading...</div>}
            {hasMoreInvoices && (
                <button 
                    className="load-more"
                    onClick={() => loadInvoices(invoicesPaginationToken)}
                    disabled={invoicesLoading}
                >
                    Carregar mais
                </button>
            )}
        </>
    );
}

export default InvoicesTab; 