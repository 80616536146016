import React, { useState, useEffect } from 'react';
import './Config.css';
import ProceduresTab from './Tabs/ProceduresTab';
import CacheTab from './Tabs/CacheTab';

function Config() {
  const [activeTab, setActiveTab] = useState('procedures');

  // Add a class to the body when component mounts
  useEffect(() => {
    document.body.classList.add('config-page');
    return () => {
      document.body.classList.remove('config-page');
    };
  }, []);

  const renderTabContent = () => {
    if (activeTab === 'cache') {
      return <CacheTab />;
    } else if (activeTab === 'procedures') {
      return <ProceduresTab />;
    }
  };

  return (
    <div className="config-container">
      <h2>Configurações</h2>
      
      <div className="tabs-container">
        <div 
          className={`tab ${activeTab === 'procedures' ? 'active' : ''}`}
          onClick={() => setActiveTab('procedures')}
        >
          Procedimentos
        </div>
        <div 
          className={`tab ${activeTab === 'cache' ? 'active' : ''}`}
          onClick={() => setActiveTab('cache')}
        >
          Cache
        </div>
      </div>
      
      <div className="tab-content">
        {renderTabContent()}
      </div>
    </div>
  );
}

export default Config; 