// src/components/Pacientes.js
import React, { useState } from 'react';
import PatientSearchSelect from '../PacientSearchSelect/PacientSearchSelect';
import { createPatient, updatePatient, fetchBookings } from '../api';
import './Pacientes.css';
import { FaSpinner } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';

function Pacientes() {
  const [patient, setPatient] = useState({
    Aniversario: '',
    CEP: '',
    Cidade: '',
    DtEntrada: new Date().toISOString().split('T')[0],
    Endereco: '',
    Estado: '',
    Fone: '',
    Nome: '',
    CPF: '',
    Email: '',
    Profissao: '',
    Sexo: '',
    Peso: '',
    Altura: '',
    isChild: false,
    NomePai: '',
    ProfissaoPai: '',
    NomeMae: '',
    ProfissaoMae: '',
    pacientId: uuidv4()
  });
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editedPatient, setEditedPatient] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ text: '', type: '' });
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [patientBookings, setPatientBookings] = useState([]);
  const [patientBookingsLoading, setPatientBookingsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const bookingsPerPage = 15;

  const handlePatientSelect = async (selectedPatient) => {
    setSelectedPatient(selectedPatient);
    if (selectedPatient && selectedPatient.pacientId) {
      await fetchPatientBookings(selectedPatient.pacientId);
    }
  };

  const fetchPatientBookings = async (patientId) => {
    try {
      setPatientBookingsLoading(true);
      const today = new Date().toISOString().split('T')[0];
      
      const queryParams = {
        patientId: patientId,
        endDate: today
      };
      
      const bookingsData = await fetchBookings(queryParams);
      
      // Sort bookings by date descending
      const sortedBookings = bookingsData
        .sort((a, b) => new Date(b.datetimeAtendimento) - new Date(a.datetimeAtendimento));
      
      setPatientBookings(sortedBookings);
      setCurrentPage(1); // Reset to first page when new patient is selected
    } catch (error) {
      console.error('Error fetching patient bookings:', error);
    } finally {
      setPatientBookingsLoading(false);
    }
  };

  // Calculate pagination
  const indexOfLastBooking = currentPage * bookingsPerPage;
  const indexOfFirstBooking = indexOfLastBooking - bookingsPerPage;
  const currentBookings = patientBookings.slice(indexOfFirstBooking, indexOfLastBooking);
  const totalPages = Math.ceil(patientBookings.length / bookingsPerPage);

  function generatePatientId() {
    return Math.random().toString(36).substring(2, 11).toUpperCase();
  }

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    setPatient({
      ...patient,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handlePatientAdd = async () => {
    setLoading(true);
    try {
      const createdPatient = await createPatient(patient);
      console.log("createdPatient", createdPatient);
      setMessage({ text: 'Paciente criado com sucesso', type: 'success' });
      setPatient({
        Aniversario: '',
        CEP: '',
        Cidade: '',
        DtEntrada: new Date().toISOString().split('T')[0],
        Endereco: '',
        Estado: '',
        Fone: '',
        Nome: '',
        CPF: '',
        Email: '',
        Profissao: '',
        Sexo: '',
        Peso: '',
        Altura: '',
        isChild: false,
        NomePai: '',
        ProfissaoPai: '',
        NomeMae: '',
        ProfissaoMae: '',
        pacientId: generatePatientId()
      });
    } catch (error) {
      const errorMessage = error.message || 'Erro ao criar paciente';
      setMessage({ text: errorMessage, type: 'error' });
    }
    setLoading(false);
  };

  const handleEditStart = () => {
    setEditMode(true);
    setEditedPatient({ ...selectedPatient });
  };

  const handleEditCancel = () => {
    setEditMode(false);
    setEditedPatient(null);
  };

  const handleEditChange = (event) => {
    setEditedPatient({
      ...editedPatient,
      [event.target.name]: event.target.value
    });
  };

  const handleEditSave = async () => {
    setLoading(true);
    try {
      const updatedPatient = await updatePatient(
        editedPatient.pacientId,
        selectedPatient.Nome,
        editedPatient
      );
      setSelectedPatient(updatedPatient);
      setEditMode(false);
      setEditedPatient(null);
      setMessage({ text: 'Paciente atualizado com sucesso', type: 'success' });
    } catch (error) {
      setMessage({ text: 'Erro ao atualizar paciente', type: 'error' });
    }
    setLoading(false);
  };

  const handleDeleteConfirm = async () => {
    setLoading(true);
    try {
      const deletedPatient = await updatePatient(
        selectedPatient.pacientId,
        selectedPatient.Nome,
        {
          ...selectedPatient,
          deleted: true,
          deletedDate: new Date().toISOString().split('T')[0]
        }
      );
      setMessage({ text: 'Paciente excluído com sucesso', type: 'success' });
      setSelectedPatient(null);
      setShowDeleteConfirm(false);
    } catch (error) {
      setMessage({ text: 'Erro ao excluir paciente', type: 'error' });
    }
    setLoading(false);
  };

  return (
    <div className="pacientes-container">
      <div className="search-section">
        <h2 className="section-title">Buscar ficha de paciente</h2>
        <PatientSearchSelect onPatientSelect={handlePatientSelect} />
        
        {selectedPatient && (
          <div className="patient-info">
            <h3 className="section-title">Informações do Paciente</h3>
            {!editMode ? (
              <>
                <div className="patient-info-grid">
                  <div className="info-item highlight-item">
                    <span className="info-label">ID:</span>
                    <span className="info-value">{selectedPatient.pacientId}</span>
                  </div>
                  <div className="info-item">
                    <span className="info-label">Nome:</span>
                    <span className="info-value">{selectedPatient.Nome}</span>
                  </div>
                  <div className="info-item">
                    <span className="info-label">CPF:</span>
                    <span className="info-value">{selectedPatient.CPF}</span>
                  </div>
                  <div className="info-item">
                    <span className="info-label">Aniversário:</span>
                    <span className="info-value">{selectedPatient.Aniversario}</span>
                  </div>
                  <div className="info-item">
                    <span className="info-label">Sexo:</span>
                    <span className="info-value">{selectedPatient.Sexo}</span>
                  </div>
                  <div className="info-item">
                    <span className="info-label">Telefone:</span>
                    <span className="info-value">{selectedPatient.Fone}</span>
                  </div>
                  <div className="info-item">
                    <span className="info-label">Email:</span>
                    <span className="info-value">{selectedPatient.Email}</span>
                  </div>
                  <div className="info-item">
                    <span className="info-label">Data de Entrada:</span>
                    <span className="info-value">{selectedPatient.DtEntrada}</span>
                  </div>
                  <div className="info-item">
                    <span className="info-label">Peso (kg):</span>
                    <span className="info-value">{selectedPatient.Peso}</span>
                  </div>
                  <div className="info-item">
                    <span className="info-label">Altura (cm):</span>
                    <span className="info-value">{selectedPatient.Altura}</span>
                  </div>
                  {selectedPatient.isChild ? (
                    <>
                      <div className="info-item">
                        <span className="info-label">Nome do Pai:</span>
                        <span className="info-value">{selectedPatient.NomePai}</span>
                      </div>
                      <div className="info-item">
                        <span className="info-label">Profissão do Pai:</span>
                        <span className="info-value">{selectedPatient.ProfissaoPai}</span>
                      </div>
                      <div className="info-item">
                        <span className="info-label">Nome da Mãe:</span>
                        <span className="info-value">{selectedPatient.NomeMae}</span>
                      </div>
                      <div className="info-item">
                        <span className="info-label">Profissão da Mãe:</span>
                        <span className="info-value">{selectedPatient.ProfissaoMae}</span>
                      </div>
                    </>
                  ) : (
                    <div className="info-item">
                      <span className="info-label">Profissão:</span>
                      <span className="info-value">{selectedPatient.Profissao}</span>
                    </div>
                  )}
                  <div className="info-item address-info">
                    <span className="info-label">Endereço:</span>
                    <span className="info-value">{selectedPatient.Endereco}</span>
                  </div>
                  <div className="info-item">
                    <span className="info-label">Cidade:</span>
                    <span className="info-value">{selectedPatient.Cidade}</span>
                  </div>
                  <div className="info-item">
                    <span className="info-label">Estado:</span>
                    <span className="info-value">{selectedPatient.Estado}</span>
                  </div>
                  <div className="info-item">
                    <span className="info-label">CEP:</span>
                    <span className="info-value">{selectedPatient.CEP}</span>
                  </div>
                </div>

                <div className="patient-actions">
                  <button className="edit-button" onClick={handleEditStart}>Editar Paciente</button>
                  <button className="delete-button" onClick={() => setShowDeleteConfirm(true)}>Excluir Paciente</button>
                </div>
                
                {showDeleteConfirm && (
                  <div className="delete-confirm">
                    <p>Tem certeza que deseja excluir este paciente?</p>
                    <div className="confirm-buttons">
                      <button className="confirm-yes" onClick={handleDeleteConfirm} disabled={loading}>
                        {loading ? <><FaSpinner className="icon-spin" /> Excluindo...</> : 'Sim'}
                      </button>
                      <button className="confirm-no" onClick={() => setShowDeleteConfirm(false)}>Não</button>
                    </div>
                  </div>
                )}

                <div className="patient-bookings-section">
                  <h4>Histórico do Paciente</h4>
                  {patientBookingsLoading ? (
                    <div className="loading-indicator">Carregando atendimentos...</div>
                  ) : (
                    <>
                      <div className="bookings-list">
                        {currentBookings.map((booking, index) => (
                          <div 
                            key={index} 
                            className={`booking-item ${booking.status === 'Realizado' ? 'completed' : ''}`}
                          >
                            <div className="booking-date">
                              {new Date(booking.datetimeAtendimento).toLocaleDateString()}
                            </div>
                            <div className="booking-time">
                              {new Date(booking.datetimeAtendimento).toLocaleTimeString()}
                            </div>
                            <div className="booking-procedures">
                              {booking.procedimentos.map((proc, i) => (
                                <span key={i} className="procedure-name">
                                  {proc.nome}
                                </span>
                              ))}
                            </div>
                            <div className="booking-status">
                              {booking.status || 'Agendado'}
                            </div>
                          </div>
                        ))}
                      </div>
                      
                      {totalPages > 1 && (
                        <div className="pagination-controls">
                          <button 
                            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                            disabled={currentPage === 1}
                          >
                            Anterior
                          </button>
                          <span>Página {currentPage} de {totalPages}</span>
                          <button 
                            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                            disabled={currentPage === totalPages}
                          >
                            Próxima
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="form-group">
                  <label>Nome</label>
                  <p className="readonly-field">{editedPatient.Nome}</p>
                </div>
                <div className="form-group">
                  <label>CPF</label>
                  <input name="CPF" value={editedPatient.CPF} onChange={handleEditChange} />
                </div>
                <div className="form-group">
                  <label>Aniversário</label>
                  <input type="date" name="Aniversario" value={editedPatient.Aniversario} onChange={handleEditChange} />
                </div>
                <div className="form-group">
                  <label>Estado</label>
                  <input name="Estado" value={editedPatient.Estado} onChange={handleEditChange} />
                </div>
                <div className="form-group">
                  <label>Cidade</label>
                  <input name="Cidade" value={editedPatient.Cidade} onChange={handleEditChange} />
                </div>
                <div className="form-group">
                  <label>Endereço</label>
                  <input name="Endereco" value={editedPatient.Endereco} onChange={handleEditChange} />
                </div>
                <div className="form-group">
                  <label>CEP</label>
                  <input name="CEP" value={editedPatient.CEP} onChange={handleEditChange} />
                </div>
                <div className="form-group">
                  <label>Data de Entrada</label>
                  <input type="date" name="DtEntrada" value={editedPatient.DtEntrada} onChange={handleEditChange} />
                </div>
                <div className="form-group">
                  <label>Telefone</label>
                  <input name="Fone" value={editedPatient.Fone} onChange={handleEditChange} />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input name="Email" value={editedPatient.Email || ''} onChange={handleEditChange} type="email" />
                </div>
                <div className="form-group">
                  <label>Sexo</label>
                  <select name="Sexo" value={editedPatient.Sexo} onChange={handleEditChange}>
                    <option value="Masculino">Masculino</option>
                    <option value="Feminino">Feminino</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Peso (kg)</label>
                  <input type="number" name="Peso" value={editedPatient.Peso} onChange={handleEditChange} step="0.1" />
                </div>
                <div className="form-group">
                  <label>Altura (cm)</label>
                  <input type="number" name="Altura" value={editedPatient.Altura} onChange={handleEditChange} />
                </div>
                <div className="form-group">
                  <label>Paciente criança</label>
                  <input 
                    type="checkbox" 
                    name="isChild" 
                    checked={editedPatient.isChild} 
                    onChange={e => setEditedPatient({
                      ...editedPatient,
                      isChild: e.target.checked
                    })} 
                  />
                </div>
                {editedPatient.isChild ? (
                  <>
                    <div className="form-group">
                      <label>Nome do Pai</label>
                      <input name="NomePai" value={editedPatient.NomePai || ''} onChange={handleEditChange} />
                    </div>
                    <div className="form-group">
                      <label>Profissão do Pai</label>
                      <input name="ProfissaoPai" value={editedPatient.ProfissaoPai || ''} onChange={handleEditChange} />
                    </div>
                    <div className="form-group">
                      <label>Nome da Mãe</label>
                      <input name="NomeMae" value={editedPatient.NomeMae || ''} onChange={handleEditChange} />
                    </div>
                    <div className="form-group">
                      <label>Profissão da Mãe</label>
                      <input name="ProfissaoMae" value={editedPatient.ProfissaoMae || ''} onChange={handleEditChange} />
                    </div>
                  </>
                ) : (
                  <div className="form-group">
                    <label>Profissão</label>
                    <input name="Profissao" value={editedPatient.Profissao || ''} onChange={handleEditChange} />
                  </div>
                )}
                <div className="edit-buttons">
                  <button className="save-button" onClick={handleEditSave} disabled={loading}>
                    {loading ? <><FaSpinner className="icon-spin" /> Salvando...</> : 'Salvar'}
                  </button>
                  <button className="cancel-button" onClick={handleEditCancel}>Cancelar</button>
                </div>
              </>
            )}
            {message.text && (
              <div className={`message ${message.type === 'success' ? 'success-message' : 'error-message'}`}>
                {message.text}
              </div>
            )}
          </div>
        )}
      </div>

      <div className="add-patient-section">
        <h2 className="section-title">Cadastrar novo Paciente</h2>
        <form className="form-grid">
          <div className="form-group">
            <label>Nome</label>
            <input
              className="form-input"
              name="Nome"
              value={patient.Nome}
              onChange={handleInputChange}
              placeholder="Nome completo"
            />
          </div>
          
          <div className="form-group">
            <label>CPF</label>
            <input
              className="form-input"
              name="CPF"
              value={patient.CPF}
              onChange={handleInputChange}
              placeholder="000.000.000-00"
            />
          </div>

          <div className="form-group">
            <label>Aniversário</label>
            <input
              className="form-input"
              type="date"
              name="Aniversario"
              value={patient.Aniversario}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group">
            <label>Estado</label>
            <input
              className="form-input"
              name="Estado"
              value={patient.Estado}
              onChange={handleInputChange}
              placeholder="Estado"
            />
          </div>

          <div className="form-group">
            <label>Cidade</label>
            <input
              className="form-input"
              name="Cidade"
              value={patient.Cidade}
              onChange={handleInputChange}
              placeholder="Cidade"
            />
          </div>

          <div className="form-group">
            <label>Endereço</label>
            <input
              className="form-input"
              name="Endereco"
              value={patient.Endereco}
              onChange={handleInputChange}
              placeholder="Endereço completo"
            />
          </div>

          <div className="form-group">
            <label>CEP</label>
            <input
              className="form-input"
              type="number"
              name="CEP"
              value={patient.CEP}
              onChange={handleInputChange}
              placeholder="00000-000"
            />
          </div>

          <div className="form-group">
            <label>Data de Entrada</label>
            <input
              className="form-input"
              type="date"
              name="DtEntrada"
              value={patient.DtEntrada}
              onChange={handleInputChange}
              onClick={(e) => e.target.showPicker()}
            />
          </div>

          <div className="form-group">
            <label>Telefone</label>
            <input
              className="form-input"
              name="Fone"
              value={patient.Fone}
              onChange={handleInputChange}
              placeholder="(00) 00000-0000"
            />
          </div>

          <div className="form-group">
            <label>Email</label>
            <input
              className="form-input"
              name="Email"
              type="email"
              value={patient.Email}
              onChange={handleInputChange}
              placeholder="email@exemplo.com"
            />
          </div>

          <div className="form-group">
            <label>Sexo</label>
            <select
              className="form-input"
              name="Sexo"
              value={patient.Sexo}
              onChange={handleInputChange}
            >
              <option value="" disabled>Selecione o sexo</option>
              <option value="Masculino">Masculino</option>
              <option value="Feminino">Feminino</option>
            </select>
          </div>

          <div className="form-group">
            <label>Peso (kg)</label>
            <input
              className="form-input"
              type="number"
              name="Peso"
              value={patient.Peso}
              onChange={handleInputChange}
              placeholder="Peso em kg"
              step="0.1"
              min="0"
            />
          </div>

          <div className="form-group">
            <label>Altura (cm)</label>
            <input
              className="form-input"
              type="number"
              name="Altura"
              value={patient.Altura}
              onChange={handleInputChange}
              placeholder="Altura em centímetros"
              step="1"
              min="0"
            />
          </div>
          <div className="form-group">
            <label>Paciente criança</label>
            <input
              className="form-checkbox"
              type="checkbox"
              name="isChild"
              checked={patient.isChild}
              onChange={handleInputChange}
            />
          </div>

          {patient.isChild ? (
            <>
              <div className="form-group">
                <label>Nome do Pai</label>
                <input
                  className="form-input"
                  name="NomePai"
                  value={patient.NomePai}
                  onChange={handleInputChange}
                  placeholder="Nome completo do pai"
                />
              </div>
              <div className="form-group">
                <label>Profissão do Pai</label>
                <input
                  className="form-input"
                  name="ProfissaoPai"
                  value={patient.ProfissaoPai}
                  onChange={handleInputChange}
                  placeholder="Profissão do pai"
                />
              </div>
              <div className="form-group">
                <label>Nome da Mãe</label>
                <input
                  className="form-input"
                  name="NomeMae"
                  value={patient.NomeMae}
                  onChange={handleInputChange}
                  placeholder="Nome completo da mãe"
                />
              </div>
              <div className="form-group">
                <label>Profissão da Mãe</label>
                <input
                  className="form-input"
                  name="ProfissaoMae"
                  value={patient.ProfissaoMae}
                  onChange={handleInputChange}
                  placeholder="Profissão da mãe"
                />
              </div>
            </>
          ) : (
            <div className="form-group">
              <label>Profissão</label>
              <input
                className="form-input"
                name="Profissao"
                value={patient.Profissao}
                onChange={handleInputChange}
                placeholder="Profissão"
              />
            </div>
          )}

        </form>

        <button
          className="submit-button"
          onClick={handlePatientAdd}
          disabled={loading}
        >
          {loading ? (
            <>
              <FaSpinner className="icon-spin" /> Adicionando...
            </>
          ) : (
            'Adicionar Paciente'
          )}
        </button>

        {message.text && (
          <div className={`message ${message.type === 'success' ? 'success-message' : 'error-message'}`}>
            {message.text}
          </div>
        )}
      </div>
    </div>
  );
}

export default Pacientes;